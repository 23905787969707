export const ManageLicenseIcon = () => {
  return (
    <svg
      width="240"
      height="160"
      viewBox="0 0 240 160"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <g clip-path="url(#clip0_208_250)">
        <path
          d="M47.7775 140.532C47.7775 140.696 47.9094 140.828 48.0736 140.828H195.704C195.868 140.828 196 140.696 196 140.532C196 140.367 195.868 140.235 195.704 140.235H48.0736C47.9094 140.235 47.7775 140.367 47.7775 140.532Z"
          fill="#2F2E43"
        />
        <path
          d="M124.332 111.308C123.927 113.765 121.788 115.646 119.218 115.646C116.648 115.646 114.51 113.765 114.105 111.308H101.022V139.661H137.415V111.308L124.332 111.308Z"
          fill="#B6B3C5"
        />
        <path
          d="M105.254 139.555H101.657V140.189H105.254V139.555Z"
          fill="#B6B3C5"
        />
        <path
          d="M137.097 139.661H133.5V140.295H137.097V139.661Z"
          fill="#B6B3C5"
        />
        <path
          d="M192.408 112.049H46.0289C44.9101 112.049 44 111.139 44 110.02V26.4814C44 25.3628 44.9101 24.4526 46.0289 24.4526H192.408C193.527 24.4526 194.437 25.3628 194.437 26.4814V110.02C194.437 111.139 193.527 112.049 192.408 112.049Z"
          fill="#2F2E41"
        />
        <path
          d="M190.946 28.0495H47.7028V108.875H190.946V28.0495Z"
          fill="white"
        />
        <path
          d="M173.928 59.0897H166.112C165.432 59.0897 164.88 58.5373 164.88 57.858C164.88 57.1788 165.432 56.6263 166.112 56.6263H173.928C174.607 56.6263 175.159 57.1788 175.159 57.858C175.159 58.5373 174.607 59.0897 173.928 59.0897Z"
          fill="#D1D1D2"
        />
        <path
          d="M182.916 59.0897H179.269C178.59 59.0897 178.037 58.5373 178.037 57.858C178.037 57.1788 178.59 56.6263 179.269 56.6263H182.916C183.595 56.6263 184.148 57.1788 184.148 57.858C184.148 58.5373 183.595 59.0897 182.916 59.0897Z"
          fill="#D1D1D2"
        />
        <path
          d="M95.6719 60.0305H71.8813C70.6669 60.0305 69.6788 59.0424 69.6788 57.8279C69.6788 56.6135 70.6669 55.6254 71.8813 55.6254H95.6719C96.8864 55.6254 97.8745 56.6135 97.8745 57.8279C97.8745 59.0424 96.8864 60.0305 95.6719 60.0305Z"
          fill="#AFAFE7"
        />
        <path
          d="M82.7734 65.9064H71.308C70.5678 65.9064 69.9655 65.3041 69.9655 64.5639C69.9655 63.8235 70.5678 63.2212 71.308 63.2212H82.7734C83.5137 63.2212 84.116 63.8235 84.116 64.5639C84.116 65.3041 83.5137 65.9064 82.7734 65.9064Z"
          fill="#D1D1D2"
        />
        <path
          d="M145.976 71.2092H71.0214C70.2811 71.2092 69.6788 70.6069 69.6788 69.8665C69.6788 69.1262 70.2811 68.5239 71.0214 68.5239H145.976C146.716 68.5239 147.319 69.1262 147.319 69.8665C147.319 70.6069 146.716 71.2092 145.976 71.2092Z"
          fill="#D1D1D2"
        />
        <path
          d="M139.527 74.7921H71.0214C70.2811 74.7921 69.6788 74.1897 69.6788 73.4494C69.6788 72.7092 70.2811 72.1069 71.0214 72.1069H139.527C140.267 72.1069 140.869 72.7092 140.869 73.4494C140.869 74.1897 140.267 74.7921 139.527 74.7921Z"
          fill="#D1D1D2"
        />
        <path
          d="M182.692 126.511L179.254 130.121L175.589 126.733L179.028 123.123L182.692 126.511Z"
          fill="#ED9DA0"
        />
        <path
          d="M185.439 130.208L178.13 137.883C177.505 138.538 176.725 139.057 175.874 139.381L173.836 140.158C173.415 140.318 172.93 140.225 172.6 139.921C172.214 139.564 172.116 138.992 172.362 138.529L174.322 134.853L176.414 127.397L176.437 127.411C177.006 127.761 177.869 128.287 177.93 128.309C179.013 128.348 179.788 128.075 180.235 127.5C181.013 126.496 180.519 124.894 180.514 124.878L180.51 124.866L180.52 124.857C180.747 124.664 180.986 124.586 181.231 124.627C181.75 124.714 182.121 125.297 182.175 125.386C182.662 125.366 184.158 126.537 184.278 126.632C185.012 126.63 185.551 126.804 185.878 127.15C186.164 127.452 186.288 127.88 186.248 128.424C186.2 129.077 185.912 129.711 185.439 130.208Z"
          fill="#090814"
        />
        <path
          d="M178.723 130.667H173.698V135.618H178.723V130.667Z"
          fill="#ED9DA0"
        />
        <path
          d="M177.865 140.121H167.184C166.271 140.121 165.354 139.915 164.531 139.526L162.562 138.593C162.155 138.4 161.891 137.988 161.891 137.543C161.891 137.021 162.248 136.559 162.759 136.42L166.821 135.313L173.771 131.714L173.776 131.74C173.906 132.388 174.107 133.368 174.133 133.427C174.846 134.232 175.578 134.602 176.309 134.529C177.584 134.402 178.432 132.951 178.441 132.936L178.447 132.925L178.46 132.926C178.759 132.957 178.98 133.075 179.117 133.279C179.408 133.711 179.23 134.377 179.201 134.477C179.55 134.813 179.706 136.689 179.718 136.841C180.222 137.367 180.461 137.873 180.43 138.344C180.402 138.756 180.17 139.139 179.74 139.482C179.223 139.894 178.557 140.121 177.865 140.121Z"
          fill="#090814"
        />
        <path
          d="M179.319 68.3167L161.514 68.3253L155.579 105.351L173.384 126.514L179.319 121.223L165.248 105.351L179.319 68.3167Z"
          fill="#090814"
        />
        <path
          d="M168.795 73.7746L179.319 68.3167V131.805L173.384 131.968L168.795 73.7746Z"
          fill="#090814"
        />
        <path
          d="M158.253 83.1644L158.379 77.3849L155.004 77.3117L154.879 83.0912C154.249 83.6756 153.834 84.5838 153.811 85.6133C153.772 87.4149 154.949 88.9015 156.44 88.9339C157.931 88.9662 159.171 87.532 159.211 85.7305C159.233 84.7009 158.857 83.7756 158.253 83.1644Z"
          fill="#ED9DA0"
        />
        <path
          d="M174.255 26.7217C174.255 26.7217 179.099 26.7112 177.289 22.0439C175.48 17.3766 172.11 19.4664 172.11 19.4664C172.11 19.4664 170.691 20.2164 170.983 21.8896"
          fill="#090814"
        />
        <path
          d="M174.74 28.9604C174.74 25.382 171.839 22.4811 168.26 22.4811C164.682 22.4811 161.781 25.382 161.781 28.9604C161.781 31.8626 163.689 34.3187 166.319 35.1436L167.572 43.4216L173.957 38.1004C173.957 38.1004 172.578 36.3434 171.838 34.3621C173.586 33.2018 174.74 31.2161 174.74 28.9604Z"
          fill="#ED9DA0"
        />
        <path
          d="M171.373 34.434C171.373 34.434 172.234 31.1351 170.827 29.6183C169.423 28.1049 169.242 30.4596 169.242 30.4596L168.229 30.2395C168.229 30.2395 168.405 27.9495 166.423 27.5531L167.7 24.8228C167.7 24.8228 162.811 25.7721 162.676 25.2986C161.616 21.5724 171.732 18.3894 174.526 24.0741C178.873 32.9157 171.373 34.434 171.373 34.434Z"
          fill="#090814"
        />
        <path
          d="M165.65 40.2062C160.946 41.1254 157.415 45.0421 156.987 49.8159L154.059 82.4631H159.197L165.65 40.2062Z"
          fill="#AFAFE7"
        />
        <path
          d="M160.792 66.2175L159.992 75.4355L181.713 69.0468L179.067 62.3842L183.995 51.067L173.934 36.6468L165.903 37.1944L162.8 41.2101"
          fill="#AFAFE7"
        />
        <path
          d="M178.506 74.9717L181.536 70.0484L178.661 68.2795L175.631 73.2028C174.793 73.3888 173.975 73.9623 173.435 74.8394C172.491 76.374 172.755 78.2517 174.025 79.0332C175.295 79.8148 177.09 79.2043 178.035 77.6696C178.574 76.7926 178.718 75.8042 178.506 74.9717Z"
          fill="#ED9DA0"
        />
        <path
          d="M172.292 36.8293L174.043 36.7567L177.038 39.0197C180.586 39.6248 181.615 39.7945 182.702 42.1383C183.159 43.1232 183.462 44.1724 183.631 45.2447L186.164 61.289L179.797 76.622L175.577 71.8761L180.088 59.8884L179.339 57.0209"
          fill="#AFAFE7"
        />
      </g>
      <defs>
        <clipPath id="clip0_208_250">
          <rect
            width="152"
            height="121.828"
            fill="white"
            transform="translate(44 19)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};
