import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import CustomSelect from "~/shared/components/customSelect";
import PageHeader from "~/shared/components/page-header.component";
import { SERVICE_ID, SERVICES_API } from "~/shared/constants/api";
import { IError } from "~/interfaces/shared.interface";
import networkService from "~/services/network.service";
import useAuthStore from "~/store/auth.store";
import useSidebarStore from "~/store/sidebar.store";
import { handleError } from "~/shared/utils/errors.util";

export default function UpdatePlan() {
  const navigate = useNavigate();
  const serviceId = SERVICE_ID;
  const token = useAuthStore((state) => state.accessToken);
  const [collapsed] = useSidebarStore((state) => [state.collapsed]);
  const [formData, setFormData] = useState({
    name: "",
    description: "",
    type: "paid",
    licenseeType: "DEVICE",
    pricingModel: {
      type: "PAY_PER_LICENSE",
      billingCycle: "",
      unitPrice: 0,
      unit: "USD",
    },
    trialPeriod: 0,
    isActive: false, // Changed from status to isActive
  });
  const [descriptionError, setDescriptionError] = useState("");
  const { planId } = useParams(); // Extract planId from useParams
  useEffect(() => {
    const fetchPlanData = async () => {
      try {
        const response = await networkService.get<any>(
          `${SERVICES_API}/${serviceId}/plans/${planId}`
        );
        const data = response.data;
        console.log(data);
        setFormData({
          name: data.name,
          description: data.description,
          type: data.type,
          licenseeType: data.licenseeType || "DEVICE",
          pricingModel: {
            type: data.pricingModel.type || "PAY_PER_LICENSE",
            billingCycle: data.pricingModel.billingCycle || "",
            unitPrice: data.pricingModel.unitPrice || 0,
            unit: data.pricingModel.unit || "USD",
          },
          trialPeriod: data.trialPeriod || 0,
          isActive: data.isActive || false, // Set isActive based on API response
        });
      } catch (error: any) {
        handleError({ error: error, message: error.message, level: "error" });
      }
    };

    fetchPlanData();
  }, [serviceId, planId, token]);

  const handleChange = (e: any) => {
    const { name, value, type, checked } = e.target;

    if (name.startsWith("pricingModel.")) {
      const pricingModelField = name.split(".")[1];
      setFormData((prevData) => ({
        ...prevData,
        pricingModel: {
          ...prevData.pricingModel,
          [pricingModelField]: type === "checkbox" ? checked : value,
        },
      }));
    } else {
      setFormData((prevData) => ({
        ...prevData,
        [name]: type === "checkbox" ? checked : value,
      }));
    }
  };

  const handleStatusChange = async (newStatusValue: string) => {
    const newStatus = newStatusValue === "Active";
    const url = newStatus
      ? `${SERVICES_API}/${serviceId}/plans/${planId}/activate`
      : `${SERVICES_API}/${serviceId}/plans/${planId}/deactivate`;

    try {
      const response = await networkService.put<any>(url, {});

      if (response) {
        setFormData((prevData) => ({
          ...prevData,
          isActive: newStatus,
        }));
        toast.success(
          `Plan ${newStatus ? "activated" : "deactivated"} successfully!`
        );
        // Confirm: navigate back
        navigate(-1);
      }
    } catch (error: any) {
      handleError({ error: error, message: error.message, level: "error" });
    }
  };

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    const { isActive, ...dataToSubmit } = formData;
    if (formData.description === "") {
      setDescriptionError("Description cannot be empty");
      return;
    }
    if (!formData.isActive) {
      setDescriptionError("Cannot update an inactive plan");
      return;
    }
    try {
      const response = await networkService.put<any>(
        `${SERVICES_API}/${serviceId}/plans/${planId}`,
        dataToSubmit
      );
      if (response) {
        toast.success("Plan Updated Successfully!");
        // Confirm: navigate back
        navigate(-1);
      } else {
        toast.error("Failed to update plan. Please try again.");
      }
    } catch (error: any) {
      handleError({ error: error, message: error.message, level: "error" });
    }
  };

  return (
    <div
      className={`flex flex-col flex-grow p-8 overflow-hidden ${collapsed ? "ml-20" : "ml-72"} transition-all duration-300`}>
      <PageHeader title="Plans" />
      <div className="sm:px-0 mt-4">
        <h3 className="text-normal font-semibold leading-7 text-Neutral-900 font-space-grotesk">
          Plans &gt; Update Plan
        </h3>
      </div>
      <form onSubmit={handleSubmit}>
        <div className="mt-4 space-y-8">
          <div className="grid gap-x-6 gap-y-8 grid-cols-4">
            <div className="col-span-2 flex flex-col gap-y-6">
              <div className="mt-2 flex flex-col gap-2">
                <label
                  htmlFor="name"
                  className="block text-sm font-medium leading-6 text-gray-900">
                  Name
                </label>
                <div className="flex w-full shadow-sm">
                  <input
                    id="name"
                    name="name"
                    type="text"
                    placeholder="Enter plan name"
                    value={formData.name}
                    onChange={handleChange}
                    disabled
                    className="px-4 py-2 w-full rounded-[1rem] border-[1px] border-[#D0D0D6] focus:outline-none disabled:bg-zinc-100 disabled:text-zinc-400
										disabled:cursor-not-allowed"
                  />
                </div>
              </div>

              <div className="mt-4 flex flex-col gap-2">
                <label
                  htmlFor="billingCycle"
                  className="block text-sm font-medium leading-6 text-gray-900">
                  Billing Cycle
                </label>
                <select
                  id="billingCycle"
                  name="pricingModel.billingCycle"
                  value={formData.pricingModel.billingCycle}
                  onChange={handleChange}
                  disabled
                  className="px-4 py-2 w-full rounded-[1rem] border-[1px] border-[#D0D0D6] focus:outline-none disabled:bg-zinc-100 disabled:text-zinc-400
									disabled:cursor-not-allowed">
                  <option value="monthly">Monthly</option>
                  <option value="quarterly">Quarterly</option>
                  <option value="yearly">Yearly</option>
                </select>
              </div>
            </div>
            <div className="col-span-2 flex flex-col gap-2">
              <label
                htmlFor="description"
                className="mt-2 text-sm font-medium leading-6 text-gray-900">
                Description
              </label>
              <div className="h-full">
                <textarea
                  id="description"
                  name="description"
                  rows={6}
                  value={formData.description}
                  onChange={handleChange}
                  className="px-4 py-1 w-full rounded-[1rem] border-[1px] border-[#D0D0D6] placeholder-gray input-text focus:outline-none"
                />
              </div>
              {descriptionError && (
                <p className="text-red-500 text-sm">{descriptionError}</p>
              )}
            </div>
          </div>

          <div className="grid gap-x-6 gap-y-4 grid-cols-4">
            <div className="col-span-1">
              <label
                htmlFor="unitPrice"
                className="block text-sm font-medium leading-6 text-gray-900">
                Pricing
              </label>
              <div>
                <input
                  id="unitPrice"
                  name="pricingModel.unitPrice"
                  type="number"
                  placeholder="Enter pricing"
                  value={formData.pricingModel.unitPrice}
                  onChange={handleChange}
                  disabled
                  className="px-4 py-2 w-full rounded-[1rem] border-[1px] border-[#D0D0D6] focus:outline-none disabled:bg-zinc-100 disabled:text-zinc-400
									disabled:cursor-not-allowed"
                />
              </div>
            </div>

            <div className="lg:col-span-1">
              <label
                htmlFor="unit"
                className="block text-sm font-medium leading-6 text-gray-900">
                Unit
              </label>
              <select
                id="unit"
                name="pricingModel.unit"
                value={formData.pricingModel.unit}
                onChange={handleChange}
                disabled
                className="px-4 py-2 w-full rounded-[1rem] border-[1px] border-[#D0D0D6] focus:outline-none disabled:bg-zinc-100 disabled:text-zinc-400
								disabled:cursor-not-allowed">
                <option value="USD">USD</option>
                <option value="INR">INR</option>
                <option value="EUR">EUR</option>
              </select>
            </div>

            <div className="col-span-2">
              <label
                htmlFor="trialPeriod"
                className="block text-sm font-medium leading-6 text-gray-900">
                Trial Period in Days (Optional)
              </label>
              <div>
                <input
                  id="trialPeriod"
                  name="trialPeriod"
                  type="number"
                  placeholder="Enter the trial period"
                  value={formData.trialPeriod}
                  onChange={handleChange}
                  disabled
                  className="px-4 py-2 w-full rounded-[1rem] border-[1px] border-[#D0D0D6] focus:outline-none disabled:bg-zinc-100 disabled:text-zinc-400
									disabled:cursor-not-allowed"
                />
              </div>
            </div>
            <div className="col-span-1">
              <label
                htmlFor="status"
                className="block mt-5 text-sm font-medium leading-6 text-gray-900">
                Status
              </label>
              <CustomSelect
                selectedValue={formData.isActive ? "Active" : "Inactive"}
                onChangeHandler={handleStatusChange}>
                <option value="Active">Active</option>
                <option value="Inactive">Inactive</option>
              </CustomSelect>
            </div>
            <div className="col-span-1">
              <label
                htmlFor="type"
                className="block mt-5 text-sm font-medium leading-6 text-gray-900">
                Plan Type
              </label>
              <select
                id="type"
                name="type"
                value={formData.type}
                onChange={handleChange}
                disabled
                className="px-4 py-2 w-full rounded-[1rem] border-[1px] border-[#D0D0D6] focus:outline-none disabled:bg-zinc-100 disabled:text-zinc-400 disabled:cursor-not-allowed">
                <option value="free">Free</option>
                <option value="paid">Paid</option>
              </select>
            </div>
          </div>
        </div>

        <div className="mt-12 flex flex-wrap justify-end gap-4">
          <button
            type="button"
            // Confirm: navigate back
            onClick={() => navigate(-1)}
            className="py-2 px-10 rounded-full border border-pink-300 text-sm sm:text-base md:text-base font-space-grotesk">
            Back
          </button>
          <button
            type="submit"
            className="py-2 px-10 rounded-full border bg-pink-300 text-sm sm:text-base md:text-base font-space-grotesk">
            Update
          </button>
        </div>
      </form>
    </div>
  );
}
