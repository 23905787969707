import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { IError } from "~/interfaces/shared.interface";
import networkService from "~/services/network.service";
import { CardDescription, CardHeader } from "~/shared/components/ui/card";
import { Label } from "~/shared/components/ui/label";
import { UserRole } from "~/shared/config";
import { CREATE_ORGANIZATION, PROFILE_INFO_API } from "~/shared/constants/api";
import { handleError } from "~/shared/utils/errors.util";
import useAuthStore from "~/store/auth.store";
4;

const CreateOrganization = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const [name, setOrgName] = useState("");
  const [description, setDescription] = useState("");

  const { user, updateUserInfo } = useAuthStore((state) => ({
    user: state.user,
    updateUserInfo: state.updateUserInfo,
  }));

  const getOrganizationDetails = async (userId: string) => {
    try {
      const response = await networkService.get<any>(
        `${PROFILE_INFO_API}/${userId}`
      );
      return response;
    } catch (error: any) {
      handleError({ error: error, message: error.message, level: "error" });
      return null;
    }
  };

  React.useEffect(() => {
    const handleNavigation = async () => {
      setLoading(true);
      try {
        const userData = await getOrganizationDetails(user.userId);
        if (userData.data.organizations.length > 0) {
          navigate("/dashboard");
        }
      } catch (error: any) {
        handleError({ error: error, message: error.message, level: "error" });
      } finally {
        setLoading(false);
      }
    };

    handleNavigation();
  }, [navigate, user.userId]);

  const createOrg = async () => {
    setLoading(true);
    try {
      const response = await networkService.post<any>(CREATE_ORGANIZATION, {
        name,
        description,
      });

      updateUserInfo({
        organizationId: response.data.id,
      });

      toast.success("Organization details added!");

      if (response.data.role === UserRole.Admin) {
        navigate("/home");
      } else {
        navigate("/dashboard");
      }
    } catch (error: any) {
      handleError({ error: error, message: error.message, level: "error" });
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="flex flex-1 flex-col justify-start items-start pt-8 px-8">
      <CardHeader className="mt-8 text-black font-sans text-4xl font-bold leading-tight">
        1. Welcome!
      </CardHeader>
      <CardDescription className="mt-0 mb-4 text-black text-lg font-opensans font-normal leading-[0px] ml-16">
        Please enter your Organization details.
      </CardDescription>

      <div className="ml-16 mt-6 sm:mx-auto w-10/12 flex flex-col">
        <form action="#" method="POST" className="space-y-6">
          <div className="flex w-full max-w-md flex-col items-start">
            <Label className="text-[#1B1B20] font-space-grotesk text-base not-italic font-normal leading-normal mb-1">
              Organization name
            </Label>
            <div className="w-full">
              <input
                className="px-4 py-2 w-full rounded-[1rem] border-[1px] border-[#D0D0D6] placeholder-gray input-text focus:outline-none bg-transparent"
                type="text"
                value={name}
                placeholder="Enter your organization name"
                onChange={(e) => setOrgName(e.target.value)}
                required
              />
            </div>
          </div>

          <div className="flex w-full max-w-md flex-col items-start">
            <Label className="text-[#1B1B20] font-space-grotesk text-base not-italic font-normal leading-normal mb-2">
              Description
            </Label>
            <div className="w-full">
              <input
                className="px-4 py-2 w-full rounded-[1rem] border-[1px] border-[#D0D0D6] placeholder-gray input-text focus:outline-none bg-transparent"
                type="description"
                value={description}
                placeholder="Describe your organization"
                onChange={(e) => setDescription(e.target.value)}
                required
              />
            </div>
          </div>

          <div className="flex w-full max-w-md gap-10 justify-center items-center rounded-[1rem] bg-[#E9C3E2]">
            <button
              className="border-none w-full py-1.5 rounded-[1rem]"
              onClick={createOrg}
              disabled={loading}>
              {loading ? "Please wait" : "Next"}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default CreateOrganization;
