import { create } from "zustand";
import { devtools, persist } from "zustand/middleware";
import { immer } from "zustand/middleware/immer";
import appConstants from "../shared/config";

interface SidebarStore {
    collapsed: boolean;
    setCollapsed: (x: boolean) => void;
}

const useSidebarStore = create<SidebarStore>()(
  devtools(
    persist(
      immer((set) => ({
        collapsed: false,
        setCollapsed: (val) =>
          set(
            (state) => {
              state.collapsed = val;
            },
            false,
            "sidebar/changeState"
          ),
      })),
      {
        name: "sidebar",
        getStorage: () => appConstants.keys.storage,
      }
    ),
    {
      name: "sidebar",
      serialize: { options: true },
    }
  )
);

export default useSidebarStore;
