import { CheckCircle } from "lucide-react";
import { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";
import { IError } from "~/interfaces/shared.interface";
import networkService from "~/services/network.service";
import CustomModal from "~/shared/components/customModal";
import DashBoardCards from "~/shared/components/dashBoard";
import { UserRole } from "~/shared/config";
import { CUSTOMER_DETAILS_API, PROFILE_INFO_API } from "~/shared/constants/api";
import { handleError } from "~/shared/utils/errors.util";
import useAuthStore from "~/store/auth.store";
import useSidebarStore from "~/store/sidebar.store";

export default function Dashboard() {
  const [firstName, setFirstName] = useState("");
  const [searchParams] = useSearchParams();
  const plan = searchParams.get("plan");
  const licenseId = searchParams.get("licenseId");
  const [openModal, setOpenModal] = useState(plan ? true : false);
  const [openLicenseModal, setOpenLicenseModal] = useState(false);
  const [openRequestModal, setOpenRequestModal] = useState(false);
  const navigate = useNavigate();
  const { user } = useAuthStore((state) => ({
    user: state.user,
  }));
  const [collapsed] = useSidebarStore((state) => [state.collapsed]);
  const formatName = (name: string) => {
    return name.charAt(0).toUpperCase() + name.slice(1).toLowerCase();
  };

  useEffect(() => {
    if (!user) {
      navigate("/login");
    }
    if (user?.role === UserRole.Admin) {
      navigate("/home");
    }
  }, [user, navigate, plan]);

  useEffect(() => {
    if (!user) return;
    const fetchUserInfo = async () => {
      try {
        const response = await networkService.get<any>(
          `${PROFILE_INFO_API}/${user?.userId}`
        );
        const data = response.data;
        setFirstName(capitalizeFirstLetter(data.firstName));
      } catch (error: any) {
        handleError({ error: error, message: error.message, level: "error" });
      }
    };

    fetchUserInfo();
  }, [user]);

  const handleRequestLicense = async () => {
    if (!user) return;
    try {
      const requestLicenseResponse = await networkService.post<any>(
        `${CUSTOMER_DETAILS_API}/${user?.organizationId}/request-new-license`,
        {}
      );
      if (requestLicenseResponse) {
        setOpenRequestModal(false);
        setOpenLicenseModal(true);
      }
    } catch (error) {
      const err = error as IError;
      err && toast.error(err.message);
    }
  };

  if (!user) return <div />;

  return (
    <div
      className={`flex flex-col flex-grow p-8 overflow-hidden ${collapsed ? "ml-20" : "ml-72"} transition-all duration-300`}>
      {/* welcome modal */}
      <CustomModal
        isOpen={openModal}
        onClose={() => {
          setOpenModal(false);
        }}
        showCloseButton={true}>
        <div className="flex flex-col gap-3 items-center font-space-grotesk">
          {/* icon */}
          <div className="rounded-full p-2 bg-confirm_green_light">
            <div className="rounded-full p-2 bg-confirm_green_dark bg-opacity-20">
              <CheckCircle className="w-7 h-7 text-green-500" />
            </div>
          </div>
          {/* text */}
          <div className="w-full flex flex-col gap-1">
            <h1 className="text-center text-2xl font-semibold">
              We offer you a free license!
            </h1>
            {/* TODO : update the text and calculate the time for the trial period */}
            <p className="text-center text-base font-normal text-black-900 text-opacity-60 max-w-[500px] mt-4">
              Here is a free{" "}
              <span className="text-gray-900 font-semibold">
                6 months trial
              </span>{" "}
              license to learn Heart Echo with HeartFocus Education. Next is to
              attach your Butterfly Probe (IQ+ or IQ3) to your licence
            </p>
          </div>
          {/* close and submit button */}
          <div className="w-full flex justify-around mt-2">
            <button
              className="text-center py-2 px-6 rounded-full bg-[#E9C3E2] text-base font-space-grotesk"
              onClick={() => {
                setOpenModal(false);
                // TODO : go the the edit a probe page where we can enable adding a probe functionality
                navigate(
                  `/probes/update-probe-details/${user.organizationId}/new?licenseId=${licenseId}`
                );
              }}>
              Attach Probe
            </button>
          </div>
        </div>
      </CustomModal>
      {/* confirmation modal */}
      <CustomModal
        isOpen={openLicenseModal}
        onClose={() => {
          setOpenLicenseModal(false);
        }}
        showCloseButton={true}>
        <div className="flex flex-col gap-3 items-center font-space-grotesk">
          {/* icon */}
          <div className="rounded-full p-2 bg-confirm_green_light">
            <div className="rounded-full p-2 bg-confirm_green_dark bg-opacity-20">
              <CheckCircle className="w-7 h-7 text-green-500" />
            </div>
          </div>
          {/* text */}
          <div className="w-full flex flex-col gap-1">
            <h1 className="text-center text-2xl font-semibold">
              Your Request has been sent!
            </h1>
            <p className="text-center text-base font-normal text-black-900 text-opacity-60 max-w-[450px] mt-4">
              We received your request and will get back to you by{" "}
              <span className="text-gray-900 font-semibold">email</span> at the
              earliest.
            </p>
          </div>
          <div className="w-full flex justify-around mt-2">
            <button
              className="text-center py-2 px-6 rounded-full bg-[#E9C3E2] text-base font-space-grotesk"
              onClick={() => {
                setOpenLicenseModal(false);
              }}>
              Close
            </button>
          </div>
        </div>
      </CustomModal>
      {/* request license modal */}
      <CustomModal
        isOpen={openRequestModal}
        onClose={() => {
          setOpenRequestModal(false);
        }}
        showCloseButton={true}>
        <div className="flex flex-col gap-3 items-center font-space-grotesk">
          {/* icon */}
          <div className="rounded-full p-2 bg-[#EDEDFA] font-sans">
            <div className="rounded-full p-2 bg-lavender-400 bg-opacity-50 flex items-center justify-center">
              <span className="text-[#6C5FC8] border-[#6C5FC8] border-2 rounded-full py-1 px-1.5 text-[10px] text-center font-bold">
                +1
              </span>
            </div>
          </div>
          {/* text */}
          <div className="w-full flex flex-col gap-1">
            <h1 className="text-center text-2xl font-semibold">
              Request new license?
            </h1>
            <p className="text-center text-base font-normal text-black-900 text-opacity-60 max-w-[450px] mt-4">
              You can request additional licenses for the HeartFocus Education
              App. Send a request and our team will come back to you quickly!
            </p>
          </div>
          <div className="w-full flex justify-between mt-4 px-4">
            <button
              className="text-center py-2 px-6 rounded-full text-base font-space-grotesk border border-zinc-900"
              onClick={() => {
                setOpenRequestModal(false);
              }}>
              Cancel
            </button>
            <button
              className="text-center py-2 px-6 rounded-full bg-[#E9C3E2] text-base font-space-grotesk"
              onClick={() => {
                handleRequestLicense();
              }}>
              Send Request
            </button>
          </div>
        </div>
      </CustomModal>
      <div className="w-full">
        <div className="inset-0 flex flex-col items-start text-white px-4 font-space-grotesk">
          <h2 className="mt-4 mb-8 text-2xl font-medium">
            Welcome {formatName(firstName)}!
          </h2>
        </div>
      </div>
      <div>
        <DashBoardCards setOpenRequestModal={setOpenRequestModal} />
      </div>
    </div>
  );
}
function capitalizeFirstLetter(
  firstName: any
): import("react").SetStateAction<string> {
  return firstName.charAt(0).toUpperCase() + firstName.slice(1);
}
