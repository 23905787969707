import { useEffect, useState } from "react";
import networkService from "~/services/network.service";
import { GET_LICENSES } from "~/shared/constants/api";
import { handleError } from "../utils/errors.util";

interface LicenseData {
  id: string;
  subscription: {
    organization: {
      id: string;
      name: string;
    };
    plan: {
      id: string;
      name: string;
      pricingModel: {
        type: string;
        billingCycle: string;
        unitPrice: number;
        unit: string;
      };
    };
  };
  device: {
    id: string;
    externalDeviceId: string;
    nickName: string;
    modelId: string;
    isActive: boolean;
  };
  createdAt: string;
  updatedAt: string;
}

interface PlanDetails {
  planName: string;
  deviceModels: string[];
  licenseCount: number;
  deviceNicknames: string[];
}

export default function LicenseProbeTable({ orgId }: { orgId: string }) {
  const [licenseDetails, setLicenseDetails] = useState<PlanDetails[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchLicenseData = async () => {
      try {
        const response = await networkService.get<any>(
          `${GET_LICENSES}/organization/${orgId}`
        );
        const data: LicenseData[] = response.data.results;
        const planMap: { [key: string]: PlanDetails } = {};

        data.forEach((license) => {
          const planName = license.subscription.plan.name;
          const deviceNickname = license?.device?.nickName;

          if (!planMap[planName]) {
            planMap[planName] = {
              planName: planName,
              deviceModels: [],
              licenseCount: 0,
              deviceNicknames: [],
            };
          }

          if (!planMap[planName].deviceNicknames.includes(deviceNickname)) {
            deviceNickname &&
              planMap[planName].deviceNicknames.push(deviceNickname);
          }

          planMap[planName].licenseCount += 1;
        });

        setLicenseDetails(Object.values(planMap));
      } catch (error: any) {
        setError("Failed to fetch license details");
        handleError({ error: error, message: error.message, level: "error" });
      } finally {
        setLoading(false);
      }
    };

    fetchLicenseData();
  }, [orgId]);

  if (error) return <div>Error: {error}</div>;

  return (
    <div className="flow-root overflow-hidden">
      <div className="mx-2 -my-1 overflow-x-auto sm:-mx-4 lg:-mx-6">
        <div className="inline-block min-w-full py-1 align-middle sm:px-4 lg:px-6">
          <div className="overflow-hidden sm:rounded-lg border border-Colors-Neutral-200 rounded-lg">
            <table className="min-w-full divide-y divide-Neutral-200 border border-Colors-Neutral-200 font-space-grotesk text-sm">
              <thead className="bg-[#EFEFF4]">
                <tr>
                  <th
                    scope="col"
                    className="py-2 pl-2 pr-2 text-left font-semibold text-gray-900 sm:pl-4 border-b border-[var(--Colors-Neutral-200, #E0E0E5)]">
                    Plan
                  </th>
                  <th
                    scope="col"
                    className="px-4 py-2 text-left font-semibold text-gray-900 border-b border-[var(--Colors-Neutral-200, #E0E0E5)]">
                    Number of Licenses
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-2 text-left font-semibold text-gray-900 border-b border-[var(--Colors-Neutral-200, #E0E0E5)]">
                    Devices
                  </th>
                </tr>
              </thead>
              <tbody className="bg-white">
                {licenseDetails.map((details) => (
                  <tr
                    key={details.planName}
                    className="border-b border-Colors-Neutral-200">
                    <td className="whitespace-nowrap py-2 pl-2 pr-2 text-sm text-gray-900 sm:pl-4">
                      {details.planName}
                    </td>
                    <td className="whitespace-nowrap px-4 py-2 text-sm text-gray-500">
                      {details.licenseCount}
                    </td>
                    <td className="whitespace-nowrap px-6 py-2 text-sm text-gray-500">
                      {details.deviceNicknames.length > 0
                        ? details.deviceNicknames.join(", ")
                        : "No device"}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}
