import { ErrorMessage, Field, Form, Formik } from "formik";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import * as Yup from "yup";
import networkService from "~/services/network.service";
import PageHeader from "~/shared/components/page-header.component";
import { PROFILE_INFO_API } from "~/shared/constants/api";
import { handleError } from "~/shared/utils/errors.util";
import useAuthStore from "~/store/auth.store";
import useSidebarStore from "~/store/sidebar.store";

const UpdateProfile = () => {
  const navigate = useNavigate();
  const [userData, setUserData] = useState(null);
  const accessToken = useAuthStore((state) => state.accessToken);
  const user = useAuthStore((state) => state.user);
  const userId = user?.userId;
  const [collapsed] = useSidebarStore((state) => [state.collapsed]);

  useEffect(() => {
    if (!userId || !accessToken) return;
    const fetchUserDetails = async () => {
      try {
        const response = await networkService.get<any>(
          `${PROFILE_INFO_API}/${userId}`
        );
        setUserData(response.data);
      } catch (error: any) {
        handleError({ error: error, message: error.message, level: "error" });
      }
    };

    fetchUserDetails();
  }, [userId, accessToken]);

  const handleSubmit = async (values: any, { setSubmitting }: any) => {
    if (!userId || !accessToken) return;

    const updateData = {
      firstName: values.firstName,
      lastName: values.lastName,
    };

    try {
      await networkService.put<any>(`${PROFILE_INFO_API}/update`, updateData);
      toast.success("User updated successfully");
      navigate(-1);
    } catch (error: any) {
      handleError({ error: error, message: error.message, level: "error" });
    } finally {
      setSubmitting(false);
    }
  };

  const updateUserSchema = Yup.object().shape({
    firstName: Yup.string()
      .min(3, "First name must be at least 6 characters")
      .required("First name is required"),
    lastName: Yup.string()
      .min(3, "Last name must be at least 6 characters")
      .required("Last name is required"),
    email: Yup.string().email("Invalid email").required("Email is required"),
  });
  console.log(userData?.firstName);

  return (
    <div
      className={`flex flex-col flex-grow p-8 overflow-hidden ${collapsed ? "ml-20" : "ml-72"} transition-all duration-300`}>
      <PageHeader title="Profile" />

      <div className="sm:px-0 mt-4">
        <h3 className="text-normal font-semibold leading-7 text-Neutral-900 font-space-grotesk">
          Profile &gt; Update Profile
        </h3>
      </div>
      <Formik
        enableReinitialize={true}
        initialValues={{
          firstName: userData?.firstName || "",
          lastName: userData?.lastName || "",
          email: userData?.email || "",
        }}
        validationSchema={updateUserSchema}
        onSubmit={handleSubmit}>
        {({ isSubmitting }) => (
          <Form>
            <div className="mt-4 space-y-4">
              <div>
                <label className="text-[#1B1B20] font-space-grotesk text-base not-italic font-normal leading-normal mb-2">
                  Email
                </label>
                <div className="w-1/2">
                  <Field
                    name="email"
                    type="text"
                    placeholder="Enter Email"
                    className="px-4 py-2 w-full rounded-[1rem] border-[1px] border-[#D0D0D6] placeholder-gray input-text focus:outline-none disabled:bg-zinc-100 disabled:cursor-not-allowed"
                    disabled
                  />
                  <ErrorMessage
                    name="email"
                    component="div"
                    className="text-red-500 text-sm mt-1"
                  />
                </div>
              </div>

              <div>
                <label className="text-[#1B1B20] font-space-grotesk text-base not-italic font-normal leading-normal mb-2">
                  First Name
                </label>
                <div className="w-1/2">
                  <Field
                    name="firstName"
                    type="text"
                    placeholder="Enter First name"
                    className="px-4 py-2 w-full rounded-[1rem] border-[1px] border-[#D0D0D6] placeholder-gray input-text focus:outline-none"
                  />
                  <ErrorMessage
                    name="firstName"
                    component="div"
                    className="text-red-500 text-sm mt-1"
                  />
                </div>
              </div>

              <div>
                <label className="text-[#1B1B20] font-space-grotesk text-base not-italic font-normal leading-normal mb-2">
                  Last Name
                </label>
                <div className="w-1/2">
                  <Field
                    name="lastName"
                    type="text"
                    placeholder="Enter First name"
                    className="px-4 py-2 w-full rounded-[1rem] border-[1px] border-[#D0D0D6] placeholder-gray input-text focus:outline-none"
                  />
                  <ErrorMessage
                    name="lastName"
                    component="div"
                    className="text-red-500 text-sm mt-1"
                  />
                </div>
              </div>
            </div>

            <div className="mt-12">
              <button
                className="py-2 px-4 sm:py-2 sm:px-6 md:py-2 md:px-6 lg:py-2 lg:px-10 rounded-full border bg-pink-300 text-sm sm:text-base md:text-base font-space-grotesk"
                type="submit"
                disabled={isSubmitting}>
                {isSubmitting ? "Updating..." : "Update"}
              </button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default UpdateProfile;
