import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { IOnboardState } from "~/interfaces/onboard.interface";
import { IError } from "~/interfaces/shared.interface";
import networkService from "~/services/network.service";
import Carousel from "~/shared/components/carousel";
import Modal from "~/shared/components/probeModal";
import { CardDescription, CardHeader } from "~/shared/components/ui/card";
import {
  ORGANIZATION_PROBES_API,
  REGISTER_DEVICE,
  UPDATE_DEVICE_ON_LICENSE,
} from "~/shared/constants/api";
import useAuthStore from "~/store/auth.store";
import useOnboardStore from "~/store/onboard.store";

const ProbeDetailsCA: React.FC = () => {
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [nickName, setNickName] = useState<string>("");
  const [externalDeviceId, setExternalDeviceId] = useState<string>("");
  const [probeIdError, setProbeIdError] = useState<string>("");
  const [screenSize, setScreenSize] = useState<number>(window.innerWidth);
  const { user } = useAuthStore((state) => ({ user: state.user }));
  const [modelId, deviceId, licenseId, setOnboardState, clearOnboardState] =
    useOnboardStore((state) => [
      state.modelId,
      state.deviceId,
      state.licenseId,
      state.setOnboardState,
      state.clearOnboardState,
    ]);
  const probeIDMinLength = 3;
  const carouselImages = ["/probe.png", "/probe1.png"];

  useEffect(() => {
    if (!user) {
      navigate("/login");
      return;
    }
    if (!modelId) {
      navigate("/add-probe/model");
      return;
    }
    const getProbeDetails = async () => {
      try {
        const organizationProbesResponse = await networkService.get<any>(
          `${ORGANIZATION_PROBES_API}/${user?.organizationId}`
        );
        const probeCount = organizationProbesResponse.data.length;
        setNickName(`probe-${probeCount ? probeCount + 1 : 1}`);
      } catch (err) {
        console.error("Failed to fetch probe details:", err);
        toast.error("An error occurred while fetching probe");
      }
    };

    getProbeDetails();
  }, [user, modelId, navigate]);

  useEffect(() => {
    window.addEventListener("resize", () => {
      setScreenSize(window.innerWidth);
    });
  }, [screenSize]);

  const handleassignLicense = async (
    licenseId: string,
    selectedDevice: string
  ) => {
    try {
      await networkService.put<any>(
        `${UPDATE_DEVICE_ON_LICENSE}/${licenseId}`,
        {
          deviceId: selectedDevice,
        }
      );
      navigate("/licenses");
    } catch (error) {
      const err = error as IError;
      toast.error(err.message);
      navigate("/licenses");
    }
  };

  const handleRegisterDevice = async () => {
    if (externalDeviceId.length === 0) {
      setProbeIdError("Probe ID is required");
      return;
    }
    if (externalDeviceId.length < probeIDMinLength) {
      setProbeIdError(
        `Probe ID must be at least ${probeIDMinLength} characters`
      );
      return;
    }

    try {
      const response = await networkService.post<any>(
        `${REGISTER_DEVICE}/${user?.organizationId}/register`,
        {
          nickName: nickName,
          externalDeviceId: externalDeviceId,
          modelId: modelId,
          isActive: true,
        }
      );

      const selectedDevice = response.data.id;
      const payload: Partial<IOnboardState> = {
        deviceId: selectedDevice,
      };

      if (deviceId !== selectedDevice) {
        payload.planId = null;
      }

      if (licenseId) {
        handleassignLicense(licenseId, payload.deviceId);
        navigate("/license");
      } else {
        setOnboardState(payload);
        navigate("/add-probe/plan");
      }
    } catch (error) {
      const err = error as IError;
      toast.error(err.message);
    }
  };

  return (
    <>
      <div className="flex flex-col justify-start items-start px-6 pt-8 lg:px-8 w-full">
        <CardHeader className="mt-8 text-black font-sans ml-16 px-0 text-4xl font-bold leading-tight">
          Enter Probe Details
        </CardHeader>
        <CardDescription className="mt-0 ml-16 text-black text-lg font-opensans font-normal leading-[0px]">
          Enter probe details below. Refer to the underline picture if needed.
        </CardDescription>
        <div className="mt-8 ml-16 w-full sm:mx-auto sm:w-full sm:max-w-sm flex flex-col flex-1 font-space-grotesk Trl">
          <div className="w-full flex flex-col items-start justify-start">
            <label className="w-3/5 text-md font-medium leading-6 text-gray-900 text-left">
              Serial Number
            </label>
            <div className="mt-2 w-full flex justify-start">
              <input
                type="text"
                placeholder="Enter Probe ID"
                value={externalDeviceId}
                onChange={(e) => setExternalDeviceId(e.target.value)}
                className="px-4 py-2 w-3/5 rounded-[1rem] border-[1px] border-[#D0D0D6] placeholder-gray input-text focus:outline-none"
              />
            </div>
            {probeIdError && (
              <span className="text-red-500 text-sm mt-1">{probeIdError}</span>
            )}
            {screenSize < 1280 && (
              <div className="mt-1 ml-14 w-1/2 flex justify-end">
                <span
                  onClick={() => setOpen(true)}
                  className="text-right cursor-pointer text-sm underline">
                  find probe serial number
                </span>
              </div>
            )}
          </div>
          <Modal
            isOpen={open}
            onClose={() => setOpen(false)}
            imageUrl="/probe.png"
            imageHeight={450}
            imageWidth={450}>
            <h2 className="text-lg font-semibold">Find Probe ID</h2>
            <p>Here you can add content or a form for finding the probe ID.</p>
          </Modal>

          <div className="w-full flex flex-col items-start mt-8">
            <label className="w-3/5 text-md font-space-grotesk font-medium leading-6 text-gray-900 text-left">
              Probe Name
            </label>
            <div className="mt-2 w-full flex justify-start">
              <input
                type="text"
                placeholder="Enter Probe Alias. Ex- John's Probe"
                value={nickName}
                onChange={(e) => setNickName(e.target.value)}
                className="px-4 py-2 w-3/5 rounded-[1rem] border-[1px] border-[#D0D0D6] placeholder-gray input-text focus:outline-none"
              />
            </div>
          </div>

          <div className="flex max-w mt-8 justify-center gap-8">
            <button
              onClick={handleRegisterDevice}
              className="w-36 py-1.5 rounded-full bg-[#26262C] text-[#FFF]">
              Next
            </button>
            {/* </Link> */}
          </div>
          <div className="fixed bottom-20 right-20">
            <div className="flex gap-2">
              <Link
                to="/add-probe/model"
                className="text-center w-36 py-2 md:py-2 md:px-2 lg:py-2 rounded-full border-2 border-[#E9C3E2] text-xs md:text-sm lg:text-sm font-space-grotesk">
                Back
              </Link>
              <Link
                to="/dashboard"
                className="text-center w-36 py-2 md:py-2 md:px-2 lg:py-2 rounded-full border-2 border-[#E9C3E2] text-xs md:text-sm lg:text-sm font-space-grotesk"
                onClick={() => {
                  clearOnboardState();
                }}>
                Cancel
              </Link>
            </div>
          </div>
        </div>
      </div>
      {screenSize > 1280 && (
        <div className="fixed w-1/3 right-[50px] top-[250px]">
          <Carousel images={carouselImages} interval={6000} />
        </div>
      )}
    </>
  );
};

export default ProbeDetailsCA;
