import React from "react";
import { IntlProvider } from "react-intl";
import { Outlet, useLocation } from "react-router-dom";
import { DEFAULT_LOCALE, messages } from "../i18n";
import useLangStore from "../store/lang.store";
import ToastWrapper from "~/shared/components/toast-wrapper.component";
import { useMatomo } from "@keiko-app/react-matomo";
import WarningModal from "~/shared/components/warningModal";
import useWarningModalStore from "~/store/warning.store";

function App() {
  const lang = useLangStore((state) => state.value);
  const { showWarningModal, isDismissed, hideWarningModal } =
    useWarningModalStore();
  const location = useLocation();
  const { tracker } = useMatomo();
  const locale = lang;

  tracker?.trackPageView();

  const excludedPaths = [
    "/",
    "/login",
    "/register",
    "forgot-password",
    "/update-password",
  ];

  const checkScreenSize = () => {
    if (excludedPaths.includes(location.pathname)) {
      hideWarningModal();
      return;
    }
    const isTabletOrSmaller = window.innerWidth < 768;
    if (!isTabletOrSmaller) hideWarningModal();
    if (isTabletOrSmaller && !isDismissed) {
      showWarningModal();
    }
  };

  React.useEffect(() => {
    checkScreenSize();
    window.addEventListener("resize", checkScreenSize);
    return () => window.removeEventListener("resize", checkScreenSize);
  }, [isDismissed]);

  React.useEffect(() => {
    checkScreenSize();
  }, [location]);

  return (
    <IntlProvider
      locale={locale}
      defaultLocale={DEFAULT_LOCALE}
      messages={messages[locale]}>
      <WarningModal />
      <Outlet />
      <ToastWrapper />
    </IntlProvider>
  );
}

export default App;
