import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { Plan } from "~/interfaces/interfaces";
import { IError } from "~/interfaces/shared.interface";
import networkService from "~/services/network.service";
import PlanCard from "~/shared/components/planSelectionCard";
import { CardDescription, CardHeader } from "~/shared/components/ui/card";
import Loader from "~/shared/components/ui/customLoader";
import { PlanId, PlanType, UserRole } from "~/shared/config";
import {
  ADD_LICENSE,
  CREATE_SUBSCRIPTION,
  GET_PLANS,
  SERVICE_ID,
  SUBSCRIBE_PAYMENT,
} from "~/shared/constants/api";
import useAuthStore from "~/store/auth.store";
import useOnboardStore from "~/store/onboard.store";

const SelectPlan = () => {
  const [loading, setLoading] = useState(true);
  const [plans, setPlans] = useState<Plan[]>([]);
  const user = useAuthStore((state) => state.user);
  const {
    manufacturerId,
    deviceId,
    planId,
    setOnboardState,
    clearOnboardState,
  } = useOnboardStore((state) => state);
  const [selectedPlan, setSelectedPlan] = useState<{
    id: string;
    type: string;
  }>();
  const navigate = useNavigate();

  // fetch plans
  useEffect(() => {
    const fetchPlans = async () => {
      const serviceId = SERVICE_ID;
      try {
        const response = await networkService.get<any>(
          `${GET_PLANS}/${serviceId}/plans`
        );
        const activePlans = response.data.results.filter(
          (plan: Plan) => plan.isActive
        );
        setPlans(activePlans);
      } catch (err) {
        toast.error("Failed to fetch plans");
      } finally {
        setLoading(false);
      }
    };

    fetchPlans();
  }, []);

  const createLicense = async function (
    subscriptionId: string,
    deviceId: string
  ) {
    try {
      // create license
      if (deviceId) {
        const licenseResponse = await networkService.post<any>(
          `${ADD_LICENSE}/${subscriptionId}/${deviceId}`,
          null
        );
        toast.success("License generated successfully.");
        return licenseResponse;
      } else {
        const licenseResponse = await networkService.post<any>(
          `${ADD_LICENSE}/${subscriptionId}`,
          null
        );
        toast.success("License generated successfully.");
        return licenseResponse;
      }
    } catch (error) {
      const err = error as IError;
      toast.error(
        err.message ??
          "An error occurred while generating the license for the subscription."
      );
      return null;
    }
  };

  const createSubscription = async function (
    organizationId: string,
    planId: string
  ) {
    try {
      const subscriptionResponse = await networkService.post<any>(
        `${CREATE_SUBSCRIPTION}/${organizationId}/${planId}`,
        {
          nickName: `Subscription to ${planId}`,
        }
      );
      if (subscriptionResponse) {
        toast.success(
          "Subscription fetched successfully. Generating license..."
        );
        return subscriptionResponse.data.subscription.id;
      }
      return null;
    } catch (error) {
      const err = error as IError;
      toast.error(
        err.message ??
          "An error occurred while processing the subscription & licenses. Please contact system administrator."
      );
      return null;
    }
  };

  const handlePlanClick = async ({
    id,
    type,
  }: {
    id: string;
    type: string;
  }) => {
    try {
      const organizationId = user?.organizationId;
      const selectedPlanObject = plans.find((plan) => plan.id === id);
      if (selectedPlanObject) {
        const subscriptionResponse = await createSubscription(
          organizationId,
          id
        );
        const licenseResponse = await createLicense(
          subscriptionResponse,
          deviceId
        );

        if (licenseResponse) {
          if (type === "free") {
            // no need to redirect to the payment gateway.
            clearOnboardState();
            // this level of check is not required but still added it just in case.
            user?.role === UserRole.Admin
              ? navigate("/home")
              : navigate(`/dashboard?plan=${PlanId.freePlanId}`);
            return;
          }
          const result = await networkService.post<any>(
            `${SUBSCRIBE_PAYMENT}/${organizationId}/${id}`,
            {
              successUrl: `${window.location.origin}/callback/success?manufacturerId=${manufacturerId}&deviceId=${deviceId}&planId=${id}&subscriptionId=${subscriptionResponse}`,
              cancelUrl: `${window.location.origin}/callback/cancel?manufacturerId=${manufacturerId}&deviceId=${deviceId}&planId=${id}&subscriptionId=${subscriptionResponse}`,
              // successUrl: `${window.location.origin}/callback/success?subscriptionId=${subscriptionResponse}`,
              // cancelUrl: `${window.location.origin}/callback/cancel?subscriptionId=${subscriptionResponse}`,
              ...(deviceId && {
                deviceId: deviceId,
              }),
              subId: subscriptionResponse,
            }
          );
          if (result.data.url) {
            window.location = result.data.url;
          }
        }
      }
    } catch (error) {
      const err = error as IError;
      toast.error(
        err.message ??
          "An error occurred while processing the subscription, licenses or payment. Please contact system administrator."
      );
    }
  };
  const handlePlanChange = ({ id, type }: { id: string; type: string }) => {
    setSelectedPlan({ id, type });
    setOnboardState({ planId: id });
  };

  //TODO : refactor this code
  useEffect(() => {
    if (!plans) return;
    if (planId === PlanId.freePlanId || planId === null) {
      plans.map((plan) => {
        if (plan.type === PlanType.Free) {
          handlePlanClick({ id: plan.id, type: plan.type });
          setOnboardState({ planId: plan.id });
        }
      });
    }
  }, [plans]);

  if (loading) {
    return <Loader />;
  }

  return (
    <div className="flex flex-1 flex-col justify-start items-start pt-8 px-8 lg:px-0">
      <CardHeader className="mt-8 ml-16 px-0 text-black font-sans text-4xl font-bold leading-tight">
        Select Plan
      </CardHeader>
      <CardDescription className="mt-0 ml-16 text-black text-lg font-opensans font-normal leading-[0px]">
        Choose the plan to fit your needs !
      </CardDescription>

      <div className="mt-8 ml-16 lg:w-full sm:mx-auto sm:w-full sm:max-w-sm flex flex-col flex-1 font-TT Firs Neue Trl">
        <div className="grid gap-6 sm:grid-cols-2 grid-cols-3 pb-10">
          {plans.map((plan) => {
            return (
              <PlanCard
                key={plan.id}
                plan={plan}
                onClick={handlePlanChange}
                isSelected={planId === plan.id}
              />
            );
          })}
        </div>
        <div className="flex fixed bottom-20 right-20 gap-2">
          <div>
            <button
              className="w-36 py-2 md:py-2 md:px-2 lg:py-2 rounded-full border-2 bg-black-900 text-white-a700 outline-black-900 border-black-900 text-xs md:text-sm lg:text-sm font-space-grotesk focus:ring"
              onClick={() => handlePlanClick(selectedPlan)}>
              Next
            </button>
          </div>
          <div className="flex gap-2">
            <Link
              to="/dashboard"
              className="text-center w-36 py-2 md:py-2 md:px-2 lg:py-2 rounded-full border-2 border-[#E9C3E2] text-xs md:text-sm lg:text-sm font-space-grotesk"
              onClick={() => {
                clearOnboardState();
              }}>
              Cancel
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SelectPlan;
