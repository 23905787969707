import { TableColumn } from "~/interfaces/interfaces";

export const compareValues = (
  a: any,
  b: any,
  direction: "ascending" | "descending",
  comparator?: (a: any, b: any, direction: "ascending" | "descending") => number
) => {
  if (comparator) {
    return comparator(a, b, direction);
  } else {
    if (a === b) {
      return 0;
    }
    return (a < b ? -1 : 1) * (direction === "ascending" ? 1 : -1);
  }
};

export const getColumnComparator = (key: string, columns: TableColumn[]) => {
  const column = columns.find((col) => col.field === key);
  return column ? column.comparator : undefined;
};
