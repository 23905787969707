export const billingCycleValues = [
  {
    value: "monthly",
    label: "Monthly",
  },
  {
    value: "quarterly",
    label: "Quarterly",
  },
  //   {
  //     value: "semiannually",
  //     label: "Semiannually",
  //   },
  {
    value: "yearly",
    label: "Yearly",
  },
];
export const unitValues = [
  {
    value: "USD",
    label: "USD",
  },
  {
    value: "INR",
    label: "INR",
  },
  {
    value: "EUR",
    label: "EUR",
  },
];

export const paymentTypeValues = [
  {
    value: "free",
    label: "Free",
  },
  {
    value: "paid",
    label: "Paid",
  },
];
