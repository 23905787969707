import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";

import PrivateRoute from "~/shared/components/private-route.component";
import App from "./app.component";

// auth routes
import ForgotPassword from "./auth/forgot-password/page";
import Login from "./auth/login/page";
import Register from "./auth/register/page";

// onboarding components
import OnboardingLayout from "./onboarding/layout";
import OnboardingManufacturer from "./onboarding/manufacturer/page";
import OnboardingModel from "./onboarding/model/page";
import OnboardingOrganization from "./onboarding/organization/page";
import OnboardingPlan from "./onboarding/plan/page";
import OnboardingProbe from "./onboarding/probe/page";

// add-probe components
import AddProbeLayout from "./add-probe/layout";
import AddProbeManufacturer from "./add-probe/manufacturer/page";
import AddProbeModel from "./add-probe/model/page";
import AddProbePlan from "./add-probe/plan/page";
import AddProbeProbe from "./add-probe/probe/page";

// callback components
import CallbackCancel from "./callback/cancel/page";
import CallbackLayout from "./callback/layout";
import CallbackSuccess from "./callback/success/page";

// dashboard components
import Dashboard from "./dashboard/page";
import Payment from "./payment/page";
import Reports from "./reports/page";

// profile components
import ProbeDetail from "./probes/[deviceId]/page";
import ProbesLayout from "./probes/layout";
import Probes from "./probes/page";
import ProbePlanUpgrade from "./probes/probe-plan-upgrade/[deviceId]/page";
import ProbeLicenseReassign from "./probes/probe-reassign-license/[deviceId]/page";
import ProbeUpdate from "./probes/update-probe-details/[orgId]/[deviceId]/page";

// probes components
import ProfileDetail from "./profile/[userId]/page";
import ProfileLayout from "./profile/layout";
import ResetPassword from "./profile/reset-password/page";
import UpdatePassword from "./profile/update-password/page";
import UpdateUser from "./profile/update-user/page";

// admin - home components
import HomePlanDetail from "./home/[planId]/page";
import HomePlanAdd from "./home/add-plan/page";
import HomeLayout from "./home/layout";
import Home from "./home/page";
import HomePlanUpdate from "./home/update-plan/[planId]/page";

// admin - customers components
import CustomerDetail from "./customers/[orgId]/page";
import CustomerLayout from "./customers/layout";
import CustomerList from "./customers/page";
import CustomerUpdate from "./customers/update-customers/[orgId]/page";

// admin - manufacturer components
import NotFound from "~/shared/components/ui/not-foundPage";
import Unauthorized from "~/shared/components/unauthorized.component";
import SidebarLayout from "~/shared/components/with-sidebar.component";
import {
  ADMIN_PERMISSION,
  CLINICAL_ADMIN_PERMISSION,
  LOGIN_PERMISSION,
} from "~/shared/config";
import ManufacturerUpdate from "./manufacturer/[manufacturerId]/page";
import ManufacturerAdd from "./manufacturer/add-manufacturer/page";
import ManufacturerLayout from "./manufacturer/layout";
import ManufacturerList from "./manufacturer/page";
import LicensesLayout from "./licenses/layout";
import Licenses from "./licenses/page";
import MarketingPage from "./marketing-page";
import VerifyEmail from "./auth/verify-email.tsx/page";
import Logout from "./auth/logout/page";

function AppRouter() {
  return (
    <BrowserRouter>
      <Routes>
        <Route element={<App />}>
          <Route index element={<Login />} />
          <Route path="login" element={<Login />} />
          <Route path="register" element={<Register />} />
          <Route path="marketing" element={<MarketingPage />} />
          <Route path="forgot-password" element={<ForgotPassword />} />
          <Route path="update-password/:token" element={<UpdatePassword />} />

          <Route element={<PrivateRoute routePermission={LOGIN_PERMISSION} />}>
            <Route path="logout" element={<Logout />} />
            <Route path="verify-email" element={<VerifyEmail />} />
            <Route path="verify-email/:token" element={<VerifyEmail />} />
            {/* onboarding routes */}
            <Route path="onboarding" element={<OnboardingLayout />}>
              {/* <Route index element={<Navigate to="./manufacturer" />} /> */}
              <Route path="organization" element={<OnboardingOrganization />} />
              {/* <Route path="manufacturer" element={<OnboardingManufacturer />} />
              <Route path="model" element={<OnboardingModel />} />
              <Route path="probe" element={<OnboardingProbe />} />
              <Route path="plan" element={<OnboardingPlan />} /> */}
            </Route>

            {/* add-probe routes */}
            <Route
              element={
                <PrivateRoute routePermission={CLINICAL_ADMIN_PERMISSION} />
              }>
              <Route path="add-probe" element={<AddProbeLayout />}>
                <Route index element={<Navigate to="./manufacturer" />} />
                <Route path="manufacturer" element={<AddProbeManufacturer />} />
                <Route path="model" element={<AddProbeModel />} />
                <Route path="probe" element={<AddProbeProbe />} />
                <Route path="plan" element={<AddProbePlan />} />
              </Route>
            </Route>

            {/* callback routes */}
            <Route path="callback" element={<CallbackLayout />}>
              <Route path="success" element={<CallbackSuccess />} />
              <Route path="cancel" element={<CallbackCancel />} />
            </Route>

            {/* routes with sidebar layout */}
            <Route element={<SidebarLayout />}>
              {/* client routes */}
              <Route path="dashboard" element={<Dashboard />} />
              <Route path="payment" element={<Payment />} />

              <Route path="probes" element={<ProbesLayout />}>
                {/* <Route index element={<Probes />} /> */}
                <Route
                  path="update-probe-details/:orgId/:deviceId"
                  element={<ProbeUpdate />}
                />
                <Route
                  path="probe-plan-upgrade/:deviceId"
                  element={<ProbePlanUpgrade />}
                />
                <Route
                  path="probe-reassign-license/:deviceId"
                  element={<ProbeLicenseReassign />}
                />
                <Route path=":deviceId" element={<ProbeDetail />} />
              </Route>

              <Route path="licenses" element={<LicensesLayout />}>
                <Route index element={<Licenses />} />
              </Route>

              <Route path="profile" element={<ProfileLayout />}>
                <Route path="reset-password" element={<ResetPassword />} />
                <Route path="update-password" element={<UpdatePassword />} />
                <Route path="update-user" element={<UpdateUser />} />
                <Route path=":userId" element={<ProfileDetail />} />
              </Route>

              {/* admin routes */}
              <Route
                element={<PrivateRoute routePermission={ADMIN_PERMISSION} />}>
                <Route path="reports" element={<Reports />} />

                {/* admin - home routes */}
                <Route path="home" element={<HomeLayout />}>
                  <Route index element={<Home />} />
                  <Route path="add-plan" element={<HomePlanAdd />} />
                  <Route
                    path="update-plan/:planId"
                    element={<HomePlanUpdate />}
                  />
                  <Route path=":planId" element={<HomePlanDetail />} />
                </Route>

                {/* admin - customers routes */}
                <Route path="customers" element={<CustomerLayout />}>
                  <Route index element={<CustomerList />} />
                  <Route
                    path="update-customers/:orgId"
                    element={<CustomerUpdate />}
                  />
                  <Route path=":orgId" element={<CustomerDetail />} />
                </Route>

                {/* admin - manufacturer routes */}
                <Route path="manufacturer" element={<ManufacturerLayout />}>
                  <Route index element={<ManufacturerList />} />
                  <Route
                    path="add-manufacturer"
                    element={<ManufacturerAdd />}
                  />
                  <Route
                    path=":manufacturerId"
                    element={<ManufacturerUpdate />}
                  />
                </Route>
              </Route>
            </Route>
          </Route>
          <Route path="unauthorized" element={<Unauthorized />} />
          <Route path="*" element={<NotFound message="Not found." />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default AppRouter;
