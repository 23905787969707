const AlphabeticalSortIcon = ({ className }: { className?: string }) => (
    <svg
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M4.13543 2.09961H4.98711L7.02097 6.79021H5.86421L5.45744 5.7987H3.63967L3.24561 6.79021H2.10156L4.13543 2.09961ZM4.5422 3.45976L3.97018 4.9216H5.11422L4.5422 3.45976Z"
        fill="#B3B3B3"
        stroke="#FEFEFE"
        strokeWidth="0.1"
      />
      <path
        d="M2.72656 11.6083L5.10364 8.78633H2.81554V7.87109H6.3621V8.78633L4.02315 11.6083H6.43837V12.5617H2.72656V11.6083Z"
        fill="#B3B3B3"
        stroke="#FEFEFE"
        strokeWidth="0.1"
      />
      <path
        d="M10.4575 9.25627V9.40627H10.6075H11.6626C11.7268 9.40627 11.7486 9.42237 11.7534 9.42655C11.7534 9.42772 11.7534 9.42956 11.7531 9.43225C11.752 9.44228 11.7465 9.46267 11.7272 9.49278C11.7272 9.49284 11.7271 9.49289 11.7271 9.49295L9.87154 12.3525L9.8712 12.3531C9.83423 12.4106 9.80058 12.4177 9.78763 12.4177C9.77468 12.4177 9.74104 12.4106 9.70407 12.3531L9.70372 12.3525L7.84816 9.49295C7.84812 9.49288 7.84807 9.49281 7.84803 9.49274C7.82872 9.46265 7.8233 9.44228 7.82215 9.43225C7.82184 9.42956 7.82183 9.42772 7.8219 9.42655C7.82664 9.42237 7.8485 9.40627 7.91266 9.40627H8.96773H9.11773V9.25627V2.6335C9.11773 2.42397 9.29209 2.24961 9.50162 2.24961H10.0736C10.2832 2.24961 10.4575 2.42397 10.4575 2.6335V9.25627Z"
        fill="#B3B3B3"
        stroke="#FEFEFE"
        strokeWidth="0.3"
      />
    </svg>
  );
  
  export default AlphabeticalSortIcon;