import { create } from "zustand";
import { devtools, persist } from "zustand/middleware";
import { immer } from "zustand/middleware/immer";
import appConstants from "../shared/config";

interface WarningModalStore {
  isModalVisible: boolean;
  isDismissed: boolean;
  showWarningModal: () => void;
  hideWarningModal: () => void;
  confirmWarningModal: () => void;
  clearAllWarningState: () => void;
}

const useWarningModalStore = create<WarningModalStore>()(
  devtools(
    persist(
      immer((set) => ({
        isModalVisible: false,
        isDismissed: false,

        showWarningModal: () =>
          set(
            (state) => {
              if (!state.isDismissed) state.isModalVisible = true;
            },
            false,
            "warningModal/show"
          ),

        hideWarningModal: () =>
          set(
            (state) => {
              state.isModalVisible = false;
            },
            false,
            "warningModal/hide"
          ),

        confirmWarningModal: () =>
          set(
            (state) => {
              state.isModalVisible = false;
              state.isDismissed = true;
            },
            false,
            "warningModal/confirm"
          ),

        clearAllWarningState: () =>
          set(
            (state) => {
              state.isModalVisible = false;
              state.isDismissed = false;
            },
            false,
            "warningModal/clearAll"
          ),
      })),
      {
        name: "warningModal",
        getStorage: () => appConstants.keys.storage,
      }
    ),
    {
      name: "warningModal",
      serialize: { options: true },
    }
  )
);

export default useWarningModalStore;
