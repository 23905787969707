import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { IError } from "~/interfaces/shared.interface";
import networkService from "~/services/network.service";
import PageHeader from "~/shared/components/page-header.component";
import Loader from "~/shared/components/ui/customLoader";
import UpgradePlanCards from "~/shared/components/upgradePlanCard";
import {
  CREATE_SUBSCRIPTION,
  DEVICE_DETAILS_API,
  DEVICE_LICENSE_DETAILS,
  MODIFY_SUBSCRIPTION_API,
} from "~/shared/constants/api";
import { handleError } from "~/shared/utils/errors.util";
import { formatDate } from "~/shared/utils/helper.util";
import useAuthStore from "~/store/auth.store";
import useSidebarStore from "~/store/sidebar.store";

interface DeviceDetails {
  id: string;
  device: {
    externalDeviceId: string;
    nickName: string;
    organizationId: string;
  };
  currentPlan?: string;
  planName?: string;
  unitPrice?: number;
  billingCycle?: string;
  createdAt?: string;
  expiryDate?: string;
  subscriptionId?: string; // Add subscriptionId here
}

export default function ProbePlanUpgrade() {
  const navigate = useNavigate();
  const [selectedPlanId, setSelectedPlanId] = useState<string | null>(null);
  const { deviceId } = useParams();
  const [probe, setProbe] = useState<DeviceDetails | null>(null);
  const [loading, setLoading] = useState<boolean | null>(true);
  const [error, setError] = useState<string | null>(null);
  const [collapsed] = useSidebarStore((state) => [state.collapsed]);

  const { accessToken } = useAuthStore((state) => ({
    accessToken: state.accessToken,
  }));

  useEffect(() => {
    const fetchDeviceDetails = async () => {
      try {
        const response = await networkService.get<any>(
          `${DEVICE_LICENSE_DETAILS}/${deviceId}`
        );

        if (response.data.length === 0) {
          throw new Error("No license data found");
        }

        const data = response.data[0] || "";

        setProbe({
          id: data.device.id,
          device: {
            externalDeviceId: data.device.externalDeviceId,
            nickName: data.device.nickName,
            organizationId: data.device.organizationId,
          },
          currentPlan: data.subscription.plan.name,
          planName: data.subscription.plan.name,
          unitPrice: data.subscription.plan.pricingModel.unitPrice,
          billingCycle: data.subscription.plan.pricingModel.billingCycle,
          createdAt: data.createdAt,
          expiryDate: data.expiryDate,
          subscriptionId: data.subscription.id,
        });
      } catch (err) {
        // If no license data is found, fetch device details only
        fetchDeviceOnlyDetails();
      } finally {
        setLoading(false);
      }
    };

    const fetchDeviceOnlyDetails = async () => {
      try {
        const response = await networkService.get<any>(
          `${DEVICE_DETAILS_API}/${deviceId}`
        );

        const data = response.data;

        setProbe({
          id: data.id,
          device: {
            externalDeviceId: data.externalDeviceId,
            nickName: data.nickName,
            organizationId: data.device.organizationId,
          },
          currentPlan: "No current plan",
          planName: "No current plan",
          unitPrice: 0,
          billingCycle: "",
          createdAt: data.device.createdAt,
          expiryDate: data.device?.expiryDate,
        });
      } catch (error: any) {
        handleError({ error: error, message: error.message, level: "error" });
        setError("Failed to fetch device details");
      }
    };

    if (deviceId) {
      fetchDeviceDetails();
    }
  }, [deviceId, accessToken]);

  const handlePlanSelect = (id: string) => {
    setSelectedPlanId(id);
  };

  const handleUpdate = async () => {
    if (!selectedPlanId || !probe) {
      setError("Please select a plan");
      return;
    }

    const nickname = `${probe.planName}_Nickname`;
    const subscriptionId = probe.subscriptionId;
    if (subscriptionId) {
      try {
        await networkService.put<any>(
          `${MODIFY_SUBSCRIPTION_API}/${subscriptionId}`,
          {
            planId: selectedPlanId,
            nickName: nickname,
          }
        );
        toast.success("Plan upgraded successfully");
        navigate(-1);
      } catch (error: any) {
        handleError({ error: error, message: error.message, level: "error" });
        setError("Failed to update subscription");
      }
    } else {
      try {
        await networkService.post<any>(
          `${CREATE_SUBSCRIPTION}/${probe.device.organizationId}/${selectedPlanId}`,
          {
            nickName: nickname,
          }
        );
        toast.success("Plan added successfully");
        navigate(-1);
      } catch (error: any) {
        handleError({ error: error, message: error.message, level: "error" });
      }
    }
  };

  if (loading) return <Loader />;

  return (
    <div
      className={`flex flex-col flex-grow p-8 overflow-hidden ${collapsed ? "ml-20" : "ml-72"} transition-all duration-300`}>
      <PageHeader title="Probes" />
      <div className="sm:px-0 mt-4">
        <h3 className="text-normal font-semibold leading-7 text-Neutral-900 font-space-grotesk">
          Probes &gt; Upgrade Probe Plan
        </h3>
      </div>
      <div className="mt-4 border-t border-gray-100">
        <dl>
          <div className="py-2 flex items-start">
            <dt className="text-sm font-semibold leading-6 text-Neutral-900 w-48 flex-shrink-0 font-space-grotesk">
              Current Plan
            </dt>
            <dd className="mt-1 text-sm leading-6 text-Neutral-900 flex items-center space-x-2 font-space-grotesk">
              <span>{probe?.currentPlan}</span>
            </dd>
          </div>
          <div className="py-2 flex items-start">
            <dt className="text-sm font-semibold leading-6 text-Neutral-900 w-48 flex-shrink-0 font-space-grotesk">
              Plan Price
            </dt>
            <dd className="mt-1 text-sm leading-6 text-Neutral-900 flex items-center space-x-2 font-space-grotesk">
              <span>
                USD {probe?.unitPrice}/{probe?.billingCycle}{" "}
              </span>
            </dd>
          </div>
          <div className="py-2 flex items-start">
            <dt className="text-sm font-semibold leading-6 text-Neutral-900 w-48 flex-shrink-0 font-space-grotesk">
              Creation Date
            </dt>
            <dd className="mt-1 text-sm leading-6 text-Neutral-900 flex items-center space-x-2 font-space-grotesk">
              <img
                src="/details/creation-date.svg"
                alt="CalenderIcon"
                width={22}
                height={22}
              />
              <span>
                {probe?.createdAt ? formatDate(probe?.createdAt) : "N/A"}
              </span>
            </dd>
          </div>
          <div className="py-2 flex items-start">
            <dt className="text-sm font-semibold leading-6 text-Neutral-900 w-48 flex-shrink-0 font-space-grotesk">
              Expiry Date
            </dt>
            <dd className="mt-1 text-sm leading-6 text-Neutral-900 flex items-center space-x-2 font-space-grotesk">
              <img
                src="/details/updated-date.svg"
                alt="CalenderIcon"
                width={22}
                height={22}
              />
              <span>
                {probe?.expiryDate ? formatDate(probe.expiryDate) : "N/A"}
              </span>
            </dd>
          </div>
        </dl>
      </div>
      <div className="border-t border-gray-200 mt-6"></div>
      <div className="py-2 flex items-start">
        <dt className="text-sm font-semibold leading-6 text-Neutral-900 w-48 flex-shrink-0 font-space-grotesk">
          Available Options
        </dt>
      </div>
      <UpgradePlanCards onPlanSelect={handlePlanSelect} />
      <div className="mt-12 flex flex-wrap justify-end gap-4">
        <button
          type="button"
          className="py-2 px-4 sm:py-2 sm:px-6 md:py-2 md:px-8 lg:py-2 lg:px-10 rounded-full border border-pink-300 text-sm sm:text-base md:text-base font-space-grotesk"
          onClick={() => navigate(-1)}>
          Back
        </button>
        <button
          type="button"
          className="py-2 px-4 sm:py-2 sm:px-6 md:py-2 md:px-6 lg:py-2 lg:px-10 rounded-full border bg-pink-300 text-sm sm:text-base md:text-base font-space-grotesk"
          onClick={handleUpdate}>
          Update
        </button>
      </div>
    </div>
  );
}
