import clsx from "clsx";
import { memo } from "react";
import { Link } from "react-router-dom";
import { SidebarNavigationItems } from "./custom-sidebar";

export const NavigationLink: React.FC<{
  item: SidebarNavigationItems;
  isActive: boolean;
  collapsed: boolean;
  isActiveClassName?: string;
  elementContainerClassName?: string;
}> = memo(
  ({
    item,
    isActive,
    collapsed,
    elementContainerClassName,
    isActiveClassName = "font-semibold bg-blue-300",
  }) => (
    <li>
      <Link
        to={item.to}
        className={clsx(
          "group flex gap-x-3 rounded-md p-2 leading-6",
          isActive ? isActiveClassName : "text-zinc-600 font-normal",
          collapsed && "items-center justify-center",
          elementContainerClassName
        )}>
        <div className="h-6 w-6 shrink-0">
          {isActive ? item.activeIcon : item.fullIcon}
        </div>
        {!collapsed && item.label}
      </Link>
    </li>
  )
);
