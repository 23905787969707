import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Manufacturer } from "~/interfaces/interfaces"; // Adjust the path as needed
import networkService from "~/services/network.service";
import { GET_MANUFACTURER } from "~/shared/constants/api";
import useAuthStore from "~/store/auth.store";
import CustomTable from "./ui/customTable"; // Import CustomTable
import { handleError } from "../utils/errors.util";

const ManufacturerTable: React.FC = () => {
  const [manufacturers, setManufacturers] = useState<Manufacturer[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  const token = useAuthStore((state) => state.accessToken);

  useEffect(() => {
    const fetchManufacturers = async () => {
      try {
        if (token) {
          const response = await networkService.get<any>(`${GET_MANUFACTURER}`);
          if (response && response.data) {
            setManufacturers(response.data);
          } else {
            throw new Error("Invalid response structure");
          }
        } else {
          throw new Error("No token found");
        }
      } catch (error: any) {
        setError("Failed to fetch manufacturers");
        handleError({ error: error, message: error.message, level: "error" });
      } finally {
        setLoading(false);
      }
    };

    fetchManufacturers();
  }, [token]); // Fetch data when token is available

  // Define columns for the CustomTable
  const columns = [
    {
      key: "name",
      header: "Manufacturer Name",
    },
    {
      key: "models",
      header: "Models",
      render: (item: Manufacturer) =>
        item.models.map((model) => model.modelName).join(", "),
    },
    {
      key: "actions",
      header: "Actions",
      render: (item: Manufacturer) => (
        <Link
          to={`/manufacturer/${item.id}`}
          className="text-indigo-600 hover:text-indigo-900">
          View details
        </Link>
      ),
    },
  ];

  return <CustomTable columns={columns} data={manufacturers} />;
};

export default ManufacturerTable;
