import { AlertTriangle, X } from "lucide-react";
import useWarningModalStore from "~/store/warning.store";

export default function WarningModal() {
  const { isModalVisible, hideWarningModal, confirmWarningModal } =
    useWarningModalStore();

  if (!isModalVisible) return null;

  return (
    <div className="fixed inset-0 flex items-center justify-center z-50 bg-black-900 bg-opacity-50">
      <div className="relative max-w-md rounded-lg p-6 shadow-lg bg-white-a700">
        <button
          onClick={hideWarningModal}
          className="absolute right-2 top-2 text-gray-500 hover:text-gray-700"
          aria-label="Close">
          <X className="h-6 w-6" />
        </button>
        <div className="flex flex-col items-center text-center">
          <AlertTriangle className="mb-4 h-12 w-12 text-yellow-500" />
          <h2 className="mb-2 text-xl font-bold text-gray-900">
            Best Viewed on Desktop or Tablet
          </h2>
          <p className="mb-6 text-gray-600 mt-2">
            We're sorry for the inconvenience! This website is currently not
            optimized for mobile devices. For the best browsing experience,
            please access our site from a desktop or tablet.
          </p>
          <div className="flex w-full flex-col space-y-2">
            <button
              onClick={confirmWarningModal}
              className="text-center py-2 px-6 rounded-full bg-gray-950 text-white-a700 text-base flex items-center gap-3 justify-center hover:bg-black-900/80">
              Proceed Anyway
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
