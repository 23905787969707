import * as Sentry from "@sentry/browser";
import { toast } from "react-toastify";

interface ErrorDetails {
  error: any;
  message?: string;
  context?: any;
  level?: Sentry.SeverityLevel;
}

export const handleError = (
  {
    error,
    message = "An unexpected error occurred.",
    context = {},
    level = "error",
  }: ErrorDetails,
  logToConsole = true
) => {
  // Optionally Log the error
  if (logToConsole) {
    console.error(`Error: ${message}`, error, context);
    toast.error(message);
  }

  // Sentry handle
  Sentry.withScope((scope) => {
    scope.setLevel(level); // "fatal" | "error" | "warning" | "log" | "info" | "debug"

    // Additional context
    if (context) {
      Object.keys(context).forEach((key) => {
        scope.setContext(key, context[key]);
      });
    }

    // Send to sentry finally
    Sentry.captureException(error);
  });
};
