import { AxiosError } from "axios";
import { IServerError } from "~/interfaces/interfaces";
import { getPropertyValue } from "~/shared/utils/helper.util";
import { loggerService } from "../logger";

const responseInterceptor = async (err: AxiosError<IServerError>) => {
  let parsedError = getPropertyValue<IServerError>(err, "response.data");

  if (!parsedError) {
    parsedError = {
      code: getPropertyValue<number>(err, "response.status"),
      message: err.message,
    };
  }
  // If invalid token error, redirect to login page
  if (err.status === 401) {
    sessionStorage.clear();
    localStorage.clear();

    if (err.response.data.message === "Invalid token") {
      window.location.href = "/login";
    }
  }
  loggerService.error("responseInterceptor", err);
  return await Promise.reject<IServerError>(parsedError);
};

export default responseInterceptor;
