import { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import networkService from "~/services/network.service";
import LicenseAndProbesTable from "~/shared/components/licensesProbeTable";
import PageHeader from "~/shared/components/page-header.component";
import ErrorPage from "~/shared/components/ui/error-page";
import NotFound from "~/shared/components/ui/not-foundPage";
import {
  CUSTOMER_DETAILS_API,
  ORGANIZATION_INVOICE_API,
  ORGANIZATION_USERS_API,
} from "~/shared/constants/api";
import { handleError } from "~/shared/utils/errors.util";
import useSidebarStore from "~/store/sidebar.store";

interface OrganizationDetails {
  orgId: string;
  name: string;
  paymentStatus: string;
  licensesAndProbes: any[];
}

interface OwnerDetails {
  orgId: string;
  email: string;
  firstName: string;
}

export default function CustomerDetails() {
  const { orgId: orgIdParam } = useParams();
  const orgId = Array.isArray(orgIdParam) ? orgIdParam[0] : orgIdParam;
  const [organization, setOrganization] = useState<OrganizationDetails | null>(
    null
  );
  const [owner, setOwner] = useState<OwnerDetails | null>(null);
  const [error, setError] = useState("");
  const [collapsed] = useSidebarStore((state) => [state.collapsed]);

  useEffect(() => {
    if (orgId) {
      const fetchOrganizationDetails = async () => {
        try {
          // Fetch organization details
          const response = await networkService.get<any>(
            `${CUSTOMER_DETAILS_API}/${orgId}`
          );
          const orgData = response.data;

          // Fetch owner details
          const usersResponse = await networkService.get<any>(
            `${ORGANIZATION_USERS_API}/${orgId}/users`
          );
          const ownerData = usersResponse.data.find(
            (user: any) => user?.role === "OWNER"
          );

          const invoiceResponse = await networkService.get<any>(
            `${ORGANIZATION_INVOICE_API}/${orgId}`
          );
          const paymentStatus =
            invoiceResponse.data.length > 0
              ? invoiceResponse.data[0].status
              : "No Invoices";

          setOrganization({
            ...orgData,
            paymentStatus: paymentStatus,
          });
          if (ownerData) {
            setOwner(ownerData.user);
          }
        } catch (error: any) {
          handleError({ error: error, message: error.message, level: "error" });
          setError("Failed to fetch organization details");
        }
      };

      fetchOrganizationDetails();
    }
  }, [orgId]);

  if (error) return <ErrorPage error={error} />;
  if (!organization) return <NotFound message="Organization not found!!" />;

  return (
    <div
      className={`flex flex-col flex-grow p-8 overflow-hidden ${collapsed ? "ml-20" : "ml-72"} transition-all duration-300`}>
      <PageHeader title="Customers" />
      <div className="sm:px-0 mt-4">
        <h3 className="text-normal font-semibold leading-7 text-Neutral-900 font-space-grotesk">
          Customers &gt; View details
        </h3>
      </div>
      <div className="mt-4 border-t border-gray-100">
        <dl>
          <div className="py-2 flex items-start">
            <dt className="text-sm font-bold leading-6 text-Neutral-900 w-48 flex-shrink-0 font-space-grotesk">
              Organization Name
            </dt>
            <dd className="mt-1 text-sm leading-6 text-Neutral-900 font-space-grotesk">
              {organization.name}
            </dd>
          </div>
          <div className="py-2 flex items-start">
            <dt className="text-sm font-bold leading-6 text-Neutral-900 w-48 flex-shrink-0 font-space-grotesk">
              Payment Status
            </dt>
            <dd className="mt-1 text-sm leading-6 text-Neutral-900 flex items-center space-x-2 font-space-grotesk">
              <img
                src="/details/price-icon.svg"
                alt="CalenderIcon"
                width={22}
                height={22}
              />
              <span>{organization.paymentStatus}</span>
              <Link
                to={`/customers/update-customers/${orgId}`}
                className="text-indigo-600 hover:text-indigo-900 ml-4">
                Update payment details
              </Link>
            </dd>
          </div>
          <div className="py-2 flex items-start">
            <dt className="text-sm font-bold leading-6 text-Neutral-900 w-48 flex-shrink-0 font-space-grotesk">
              Licenses and probes
            </dt>
            <dd className="w-full text-sm leading-6 text-Neutral-900 flex flex-col font-space-grotesk">
              <LicenseAndProbesTable orgId={orgId} />
            </dd>
          </div>
          {owner && (
            <>
              <div className="py-2 flex items-start">
                <dt className="text-sm font-bold leading-6 text-Neutral-900 w-48 flex-shrink-0 font-space-grotesk">
                  Owner Name
                </dt>
                <dd className="mt-1 text-sm leading-6 text-Neutral-900 flex items-center space-x-2 font-space-grotesk">
                  <span>{owner.firstName}</span>
                </dd>
              </div>
              <div className="py-2 flex items-start">
                <dt className="text-sm font-bold leading-6 text-Neutral-900 w-48 flex-shrink-0 font-space-grotesk">
                  Email
                </dt>
                <dd className="mt-1 text-sm leading-6 text-Neutral-900 flex items-center space-x-2 font-space-grotesk">
                  <span>{owner.email}</span>
                </dd>
              </div>
            </>
          )}
        </dl>
      </div>
      <div className="mt-12 flex flex-wrap gap-4 ">
        <Link
          to={`/customers/update-customers/${orgId}`}
          className="py-2 px-10 rounded-full border bg-pink-300 text-sm sm:text-base md:text-base font-space-grotesk">
          Update
        </Link>
      </div>
    </div>
  );
}
