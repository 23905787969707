export const DownloadAppIcon = () => {
  return (
    <svg
      width="240"
      height="160"
      viewBox="0 0 240 160"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M198.237 133.768V25.7754C198.237 22.4355 195.529 19.7279 192.189 19.7279H89.8867C89.13 19.7279 89.3805 18 87.6526 18H77.7173C75.9895 18 76.24 19.7279 75.4833 19.7279H47.0476C43.7076 19.7279 41 22.4355 41 25.7754V133.768C41 137.108 43.7076 139.815 47.0476 139.815H71.5956C72.3523 139.815 73.0729 141.543 73.8296 141.543H80.3092C82.037 141.543 81.9919 139.815 82.469 139.815C82.9461 139.815 82.901 141.543 84.1969 141.543H91.5403C93.2682 141.543 93.0177 139.815 93.7744 139.815H192.189C195.529 139.815 198.237 137.108 198.237 133.768Z"
        fill="#3F3D56"
      />
      <path
        d="M196.941 133.012V26.9626C196.941 23.6827 194.278 21.0238 190.993 21.0238H48.2438C44.9589 21.0238 42.2959 23.6827 42.2959 26.9626V133.012C42.2959 136.292 44.9589 138.951 48.2438 138.951H190.993C194.278 138.951 196.941 136.292 196.941 133.012Z"
        fill="#2F2E41"
      />
      <path
        d="M190.976 22.9917H48.374C46.1184 22.9917 44.2899 24.8202 44.2899 27.0757V132.581C44.2899 134.836 46.1184 136.665 48.374 136.665H190.976C193.232 136.665 195.06 134.836 195.06 132.581V27.0757C195.06 24.8202 193.232 22.9917 190.976 22.9917Z"
        fill="#F0F0F0"
      />
      <path
        d="M119.606 98.9763C130.188 98.9763 138.766 90.398 138.766 79.8161C138.766 69.2342 130.188 60.6558 119.606 60.6558C109.024 60.6558 100.446 69.2342 100.446 79.8161C100.446 90.398 109.024 98.9763 119.606 98.9763Z"
        fill="#AFAFE7"
      />
      <path
        d="M125.284 81.1253L120.519 86.033C120.415 86.1375 120.291 86.2198 120.155 86.2748C120.018 86.3298 119.872 86.3564 119.725 86.3529H119.709C119.562 86.3562 119.415 86.3295 119.278 86.2745C119.141 86.2195 119.017 86.1374 118.913 86.033L114.15 81.1253C114.134 81.1072 114.116 81.0913 114.1 81.0754C113.91 80.8715 113.809 80.6006 113.818 80.322C113.827 80.0434 113.947 79.7799 114.15 79.5893C114.368 79.3849 114.655 79.2711 114.953 79.2711C115.252 79.2711 115.539 79.3849 115.757 79.5893L118.579 82.5389V74.9334C118.592 74.6405 118.718 74.3639 118.93 74.1612C119.142 73.9586 119.424 73.8455 119.717 73.8455C120.01 73.8455 120.292 73.9586 120.504 74.1612C120.716 74.3639 120.842 74.6405 120.855 74.9334V82.5389L123.675 79.5893C123.893 79.3848 124.181 79.271 124.479 79.271C124.778 79.271 125.066 79.3848 125.284 79.5893C125.3 79.6051 125.316 79.621 125.334 79.6392C125.523 79.8433 125.624 80.1141 125.615 80.3925C125.606 80.671 125.487 80.9344 125.284 81.1253Z"
        fill="white"
      />
    </svg>
  );
};
