import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { Formik, Field, FieldArray, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import PageHeader from "~/shared/components/page-header.component";
import { CREATE_MANUFACTURER } from "~/shared/constants/api";
import networkService from "~/services/network.service";
import useSidebarStore from "~/store/sidebar.store";
import { handleError } from "~/shared/utils/errors.util";

// Validation Schema using Yup
const validationSchema = Yup.object().shape({
  name: Yup.string().required("Manufacturer name is required"),
  models: Yup.array().of(
    Yup.object().shape({
      modelName: Yup.string().required("Model name is required"),
      instructions: Yup.string().required("Instructions are required"),
    })
  ),
  applications: Yup.array().of(
    Yup.object().shape({
      applicationName: Yup.string().required("Application name is required"),
      applicationDescription: Yup.string().required(
        "Application description is required"
      ),
    })
  ),
});

export default function AddManufacturer() {
  const navigate = useNavigate();
  const [collapsed] = useSidebarStore((state) => [state.collapsed]);
  // Initial Form State
  const initialValues = {
    name: "",
    models: [
      {
        modelName: "",
        instructions: "",
      },
    ],
    applications: [
      {
        applicationName: "",
        applicationDescription: "",
      },
    ],
  };

  // Handle Form Submission
  const handleSubmit = async (values: typeof initialValues) => {
    try {
      const response = await networkService.post<any>(
        `${CREATE_MANUFACTURER}`,
        {
          name: values.name,
          models: values.models,
          applications: values.applications,
        }
      );

      if (response) {
        const manufacturerId = response.data.id;
        toast.success("Manufacturer Created Successfully!");
        navigate(`/manufacturer/${manufacturerId}`);
      }
    } catch (error: any) {
      handleError({ error: error, message: error.message, level: "error" });
    }
  };

  return (
    <div
      className={`flex flex-col flex-grow p-8 overflow-hidden ${collapsed ? "ml-20" : "ml-72"} transition-all duration-300`}>
      <PageHeader title="Manufacturer" />
      <div className="sm:px-0 mt-4">
        <h3 className="text-normal font-semibold leading-7 text-Neutral-900 font-space-grotesk">
          Manufacturer &gt; Add Manufacturer
        </h3>
      </div>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}>
        {({ values, setFieldValue, isSubmitting }) => (
          <Form>
            <div className="mt-4 space-y-8">
              <div className="grid grid-cols-1 w-full gap-x-6 gap-y-8 lg:grid-cols-">
                <div className="col-span-2 flex flex-col gap-y-6">
                  <div>
                    <label
                      htmlFor="name"
                      className="text-sm font-medium leading-6 text-gray-900">
                      Manufacturer Name
                    </label>
                    <div className="mt-2">
                      <Field
                        id="name"
                        name="name"
                        type="text"
                        placeholder="Enter manufacturer name"
                        autoComplete="name"
                        className="px-4 py-2 w-2/5 rounded-[1rem] border-[1px] border-[#D0D0D6] placeholder-gray input-text focus:outline-none"
                      />
                      <ErrorMessage
                        name="name"
                        component="div"
                        className="text-red-500 text-sm mt-1"
                      />
                    </div>
                  </div>
                </div>
              </div>

              <FieldArray name="models">
                {({ push, remove }) => (
                  <>
                    {values.models.map((model, index) => (
                      <div
                        key={index}
                        className="grid grid-cols-1 gap-x-6 gap-y-4 lg:grid-cols-">
                        <div className="lg:col-span-2 flex flex-col gap-y-6">
                          <div className="flex gap-x-4">
                            <div className="flex-grow">
                              <label
                                htmlFor={`models.${index}.modelName`}
                                className="text-sm font-medium leading-6 text-gray-900">
                                Model Name
                              </label>
                              <div className="mt-2">
                                <Field
                                  name={`models.${index}.modelName`}
                                  type="text"
                                  placeholder="Enter model name"
                                  autoComplete={`models.${index}.modelName`}
                                  className="px-4 py-2 w-full rounded-[1rem] border-[1px] border-[#D0D0D6] placeholder-gray input-text focus:outline-none"
                                />
                                <ErrorMessage
                                  name={`models.${index}.modelName`}
                                  component="div"
                                  className="text-red-500 text-sm mt-1"
                                />
                              </div>
                            </div>

                            <div className="flex-grow">
                              <label
                                htmlFor={`models.${index}.instructions`}
                                className="text-sm font-medium leading-6 text-gray-900">
                                Instructions
                              </label>
                              <div className="mt-2">
                                <Field
                                  name={`models.${index}.instructions`}
                                  type="text"
                                  placeholder="Enter instructions"
                                  autoComplete={`models.${index}.instructions`}
                                  className="px-4 py-2 w-full rounded-[1rem] border-[1px] border-[#D0D0D6] placeholder-gray input-text focus:outline-none"
                                />
                                <ErrorMessage
                                  name={`models.${index}.instructions`}
                                  component="div"
                                  className="text-red-500 text-sm mt-1"
                                />
                              </div>
                            </div>

                            <div className="flex items-center gap-x-4">
                              <button
                                type="button"
                                onClick={() => remove(index)}
                                className="py-2 px-2">
                                <img
                                  src="/trash.svg"
                                  alt="Delete"
                                  width={20}
                                  height={20}
                                />
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                    <button
                      type="button"
                      onClick={() =>
                        push({
                          modelName: "",
                          instructions: "",
                          image: "",
                        })
                      }
                      className="w-1/6 py-2 rounded-full border border-[#AFAFE7] text-sm font-space-grotesk">
                      + Add Model
                    </button>
                  </>
                )}
              </FieldArray>

              <FieldArray name="applications">
                {({ push, remove }) => (
                  <>
                    {values.applications.map((application, index) => (
                      <div
                        key={index}
                        className="grid grid-cols-1 gap-x-6 gap-y-4 lg:grid-cols-">
                        <div className="lg:col-span-2 flex flex-col gap-y-6">
                          <div className="flex gap-x-4">
                            <div className="flex-grow">
                              <label
                                htmlFor={`applications.${index}.applicationName`}
                                className="text-sm font-medium leading-6 text-gray-900">
                                Application Name
                              </label>
                              <div className="mt-2">
                                <Field
                                  name={`applications.${index}.applicationName`}
                                  type="text"
                                  placeholder="Enter application name"
                                  autoComplete={`applications.${index}.applicationName`}
                                  className="px-4 py-2 w-full rounded-[1rem] border-[1px] border-[#D0D0D6] placeholder-gray input-text focus:outline-none"
                                />
                                <ErrorMessage
                                  name={`applications.${index}.applicationName`}
                                  component="div"
                                  className="text-red-500 text-sm mt-1"
                                />
                              </div>
                            </div>

                            <div className="flex-grow">
                              <label
                                htmlFor={`applications.${index}.applicationDescription`}
                                className="text-sm font-medium leading-6 text-gray-900">
                                Application Description
                              </label>
                              <div className="mt-2">
                                <Field
                                  name={`applications.${index}.applicationDescription`}
                                  type="text"
                                  placeholder="Enter application description"
                                  autoComplete={`applications.${index}.applicationDescription`}
                                  className="px-4 py-2 w-full rounded-[1rem] border-[1px] border-[#D0D0D6] placeholder-gray input-text focus:outline-none"
                                />
                                <ErrorMessage
                                  name={`applications.${index}.applicationDescription`}
                                  component="div"
                                  className="text-red-500 text-sm mt-1"
                                />
                              </div>
                            </div>

                            <button
                              type="button"
                              onClick={() => remove(index)}
                              className="py-2 px-2">
                              <img
                                src="/trash.svg"
                                alt="Delete"
                                width={20}
                                height={20}
                              />
                            </button>
                          </div>
                        </div>
                      </div>
                    ))}
                    <button
                      type="button"
                      onClick={() =>
                        push({
                          applicationName: "",
                          applicationDescription: "",
                        })
                      }
                      className="w-1/6 py-2 rounded-full border border-[#AFAFE7] text-sm font-space-grotesk">
                      + Add Application
                    </button>
                  </>
                )}
              </FieldArray>

              <div className="mt-12 flex flex-wrap justify-end gap-4">
                <button
                  type="button"
                  // Confirm: navigate back
                  onClick={() => navigate(-1)}
                  className="py-2 px-4 sm:py-2 sm:px-6 md:py-2 md:px-8 lg:py-2 lg:px-10 rounded-full border border-pink-300 text-sm sm:text-base md:text-base font-space-grotesk">
                  Back
                </button>
                <button
                  type="submit"
                  className="py-2 px-4 sm:py-2 sm:px-6 md:py-2 md:px-6 lg:py-2 lg:px-10 rounded-full border bg-pink-300 text-sm sm:text-base md:text-base font-space-grotesk">
                  Save
                </button>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
}
