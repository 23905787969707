import { ErrorMessage, Field, Form, Formik } from "formik";
import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import * as Yup from "yup";
import networkService from "~/services/network.service";
import BgImage from "~/shared/components/bgImage";
import { CardDescription, CardHeader } from "~/shared/components/ui/card";
import { Label } from "~/shared/components/ui/label";
import { IError } from "~/interfaces/shared.interface";
import { UPDATE_PASSWORD } from "~/shared/constants/api";
import axios, { Axios, AxiosError } from "axios";
import { handleError } from "~/shared/utils/errors.util";

const UpdatePassword: React.FC = () => {
  const navigate = useNavigate();
  const { token } = useParams();

  useEffect(() => {
    if (!token) {
      navigate("/login");
    }
  }, [token, navigate]);

  const changePassword = async (password: string) => {
    if (!token) return;
    try {
      const response = await axios.post(`${UPDATE_PASSWORD}/${token}`, {
        newPassword: password,
      });
      if (response) {
        navigate("/login");
        toast.success("Password Updated Successfully");
      }
    } catch (err: any) {
      const error = err as AxiosError<IError>;
      handleError({ error: error, message: error.message, level: "error" });
    }
  };

  const validationSchema = Yup.object({
    password: Yup.string()
      .min(6, "Password must be at least 6 characters")
      .required("New password is required"),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref("password"), null], "Passwords must match")
      .required("Confirm password is required"),
  });

  return (
    <div className="flex min-h-screen font-space-grotesk">
      <BgImage />
      <div className="flex flex-1 flex-col justify-start items-start px-6 lg:px-8 z-10 absolute top-40 left-24">
        <div className="flex flex-1 flex-col justify-start items-start px-6 pt-10 lg:px-8">
          <CardHeader className="mt-8 text-black font-sans text-4xl font-bold leading-tight">
            Welcome!
          </CardHeader>
          <CardDescription className="mt-0 ml-6 text-black text-lg font-opensans font-normal leading-[21px]">
            Please enter your new password .
          </CardDescription>

          <div className="mt-6 ml-6 flex flex-col flex-1 min-w-[500px]">
            <Formik
              enableReinitialize
              initialValues={{ password: "", confirmPassword: "" }}
              validationSchema={validationSchema}
              onSubmit={(values) => {
                changePassword(values.password);
              }}>
              {({ handleChange, values }) => (
                <Form className="space-y-6 flex-1">
                  <div className="flex mt-auto w-full max-w-md flex-col items-start flex-shrink-0">
                    <Label className="text-[#1B1B20] font-space-grotesk text-base not-italic font-normal leading-normal mb-4">
                      Password
                    </Label>
                    <div className="w-full">
                      <Field
                        name="password"
                        type="password"
                        className="px-4 bg-transparent py-2 w-full rounded-[1rem] border-[1px] border-[#D0D0D6] placeholder-gray input-text focus:outline-none"
                        placeholder="Enter the new password"
                        value={values.password}
                        onChange={handleChange}
                      />
                      <ErrorMessage
                        name="password"
                        component="div"
                        className="text-red-600 text-sm mt-1"
                      />
                    </div>
                  </div>

                  <div className="flex mt-auto w-full max-w-md flex-col items-start flex-shrink-0">
                    <Label className="text-[#1B1B20] font-space-grotesk text-base not-italic font-normal leading-normal mb-4">
                      Confirm New Password
                    </Label>
                    <div className="w-full">
                      <Field
                        name="confirmPassword"
                        type="password"
                        className="px-4 bg-transparent py-2 w-full rounded-[1rem] border-[1px] border-[#D0D0D6] placeholder-gray input-text focus:outline-none"
                        placeholder="Confirm the new password"
                        value={values.confirmPassword}
                        onChange={handleChange}
                      />
                      <ErrorMessage
                        name="confirmPassword"
                        component="div"
                        className="text-red-600 text-sm mt-1"
                      />
                    </div>
                  </div>
                  <div className="flex gap-3">
                    <div className="flex w-1/2 max-w-md gap-10 justify-center items-center rounded-full bg-[#E9C3E2]">
                      <button
                        type="submit"
                        className="border-none w-full py-2 rounded-[1rem]">
                        Change Password
                      </button>
                    </div>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UpdatePassword;
