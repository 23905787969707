export const RequestLicenseIcon = () => {
  return (
    <svg
      width="240"
      height="160"
      viewBox="0 0 240 160"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <g clip-path="url(#clip0_208_296)">
        <path
          d="M197.507 66.2578H197.464L172.981 76.7003L139.484 90.9879C139.353 91.0429 139.212 91.0716 139.07 91.0723C138.928 91.0731 138.788 91.0458 138.656 90.9922L104.096 76.6705L79.0119 66.2748L78.9735 66.2578H78.9309C78.5351 66.2582 78.1556 66.4154 77.8757 66.695C77.5959 66.9745 77.4384 67.3536 77.438 67.7489V132.509C77.4384 132.904 77.5959 133.283 77.8757 133.563C78.1556 133.842 78.5351 134 78.9309 134H197.507C197.903 134 198.282 133.842 198.562 133.563C198.842 133.283 199 132.904 199 132.509V67.7489C199 67.3536 198.842 66.9745 198.562 66.695C198.282 66.4154 197.903 66.2582 197.507 66.2578Z"
          fill="white"
        />
        <path
          d="M197.934 66.6838C197.89 66.6838 197.848 66.6705 197.812 66.6456L138.831 25.6161C138.652 25.4918 138.438 25.4254 138.22 25.4258C138.001 25.4261 137.788 25.4933 137.609 25.6182L79.0534 66.6452C79.0305 66.6613 79.0046 66.6727 78.9773 66.6788C78.95 66.6848 78.9217 66.6855 78.8941 66.6806C78.8665 66.6758 78.8401 66.6656 78.8165 66.6506C78.7929 66.6356 78.7724 66.616 78.7563 66.5931C78.7402 66.5702 78.7288 66.5444 78.7227 66.5171C78.7167 66.4897 78.716 66.4615 78.7209 66.4339C78.7257 66.4064 78.7359 66.3801 78.751 66.3564C78.766 66.3328 78.7855 66.3124 78.8085 66.2963L137.364 25.2693C137.615 25.0945 137.913 25.0005 138.219 25C138.525 24.9995 138.824 25.0925 139.075 25.2665L198.056 66.2959C198.093 66.3219 198.121 66.359 198.136 66.4019C198.15 66.4449 198.151 66.4913 198.137 66.5346C198.124 66.5779 198.096 66.6157 198.06 66.6425C198.023 66.6694 197.979 66.6838 197.934 66.6838Z"
          fill="#3F3D56"
        />
        <path
          d="M82.3995 68.1382L138.278 26.7722L194.58 71.0816L141.157 102.716L112.153 96.1121L82.3995 68.1382Z"
          fill="#F0F0F0"
        />
        <path
          d="M114.601 122.255H86.9956C86.8261 122.255 86.6582 122.222 86.5015 122.157C86.3449 122.093 86.2025 121.998 86.0826 121.878C85.9626 121.759 85.8675 121.617 85.8026 121.46C85.7376 121.304 85.7042 121.136 85.7042 120.967C85.7042 120.798 85.7376 120.63 85.8026 120.473C85.8675 120.317 85.9626 120.175 86.0826 120.055C86.2025 119.936 86.3449 119.841 86.5015 119.776C86.6582 119.712 86.8261 119.678 86.9956 119.679H114.601C114.77 119.678 114.938 119.712 115.095 119.776C115.252 119.841 115.394 119.936 115.514 120.055C115.634 120.175 115.729 120.317 115.794 120.473C115.859 120.63 115.892 120.798 115.892 120.967C115.892 121.136 115.859 121.304 115.794 121.46C115.729 121.617 115.634 121.759 115.514 121.878C115.394 121.998 115.252 122.093 115.095 122.157C114.938 122.222 114.77 122.255 114.601 122.255Z"
          fill="#AFAFE7"
        />
        <path
          d="M96.8997 116.716H86.9956C86.8261 116.717 86.6582 116.683 86.5015 116.619C86.3449 116.554 86.2025 116.459 86.0826 116.34C85.9626 116.22 85.8675 116.078 85.8026 115.922C85.7376 115.765 85.7042 115.597 85.7042 115.428C85.7042 115.259 85.7376 115.091 85.8026 114.935C85.8675 114.778 85.9626 114.636 86.0826 114.517C86.2025 114.397 86.3449 114.302 86.5015 114.238C86.6582 114.173 86.8261 114.14 86.9956 114.14H96.8997C97.0693 114.14 97.2371 114.173 97.3938 114.238C97.5505 114.302 97.6928 114.397 97.8128 114.517C97.9327 114.636 98.0279 114.778 98.0928 114.935C98.1577 115.091 98.1911 115.259 98.1911 115.428C98.1911 115.597 98.1577 115.765 98.0928 115.922C98.0279 116.078 97.9327 116.22 97.8128 116.34C97.6928 116.459 97.5505 116.554 97.3938 116.619C97.2371 116.683 97.0693 116.717 96.8997 116.716Z"
          fill="#AFAFE7"
        />
        <path
          d="M139.064 91.1785C138.854 91.1787 138.646 91.1381 138.452 91.0588L103.99 76.7757V32.0671C103.99 31.6717 104.148 31.2927 104.428 31.0131C104.707 30.7336 105.087 30.5763 105.483 30.5759H171.595C171.991 30.5763 172.37 30.7336 172.65 31.0131C172.93 31.2927 173.088 31.6717 173.088 32.0671V76.8069L173.023 76.8345L139.694 91.0518C139.494 91.1356 139.28 91.1787 139.064 91.1785Z"
          fill="white"
        />
        <path
          d="M139.064 91.2849C138.84 91.2852 138.618 91.2419 138.411 91.1574L103.883 76.8468V32.0671C103.884 31.6435 104.052 31.2374 104.352 30.9378C104.652 30.6383 105.059 30.4698 105.483 30.4694H171.595C172.019 30.4698 172.426 30.6383 172.726 30.9378C173.026 31.2374 173.194 31.6435 173.195 32.0671V76.8772L139.736 91.1497C139.523 91.2392 139.295 91.2851 139.064 91.2849ZM104.31 76.5622L138.574 90.7635C138.893 90.893 139.251 90.8909 139.569 90.7576L172.768 76.5959V32.0671C172.768 31.7564 172.644 31.4586 172.424 31.239C172.204 31.0193 171.906 30.8958 171.595 30.8954H105.483C105.172 30.8958 104.874 31.0193 104.654 31.239C104.434 31.4586 104.31 31.7564 104.31 32.0671V76.5622Z"
          fill="#3F3D56"
        />
        <path
          d="M197.507 66.2578H197.464L172.981 76.7003L139.484 90.9879C139.353 91.0429 139.212 91.0716 139.07 91.0723C138.928 91.0731 138.788 91.0458 138.656 90.9922L104.096 76.6705L79.0119 66.2748L78.9735 66.2578H78.9309C78.5351 66.2582 78.1556 66.4154 77.8757 66.695C77.5959 66.9745 77.4384 67.3536 77.438 67.7489V132.509C77.4384 132.904 77.5959 133.283 77.8757 133.563C78.1556 133.842 78.5351 134 78.9309 134H197.507C197.903 134 198.282 133.842 198.562 133.563C198.842 133.283 199 132.904 199 132.509V67.7489C199 67.3536 198.842 66.9745 198.562 66.695C198.282 66.4154 197.903 66.2582 197.507 66.2578ZM198.573 132.509C198.573 132.791 198.461 133.062 198.261 133.262C198.061 133.462 197.79 133.574 197.507 133.574H78.9309C78.6481 133.574 78.3769 133.462 78.177 133.262C77.977 133.062 77.8646 132.791 77.8645 132.509V67.7489C77.8649 67.4735 77.9719 67.2089 78.163 67.0104C78.3542 66.8119 78.6148 66.6949 78.8903 66.6838L104.096 77.1306L138.492 91.3863C138.864 91.5376 139.282 91.5353 139.652 91.3799L172.981 77.1625L197.55 66.6838C197.825 66.6957 198.085 66.813 198.275 67.0114C198.466 67.2098 198.573 67.4739 198.573 67.7489V132.509Z"
          fill="#3F3D56"
        />
        <path
          d="M133.698 47.2881H110.239C109.787 47.2881 109.353 47.1086 109.033 46.7889C108.713 46.4693 108.533 46.0359 108.533 45.5839C108.533 45.1319 108.713 44.6984 109.033 44.3788C109.353 44.0592 109.787 43.8797 110.239 43.8797H133.698C134.151 43.8797 134.585 44.0592 134.905 44.3788C135.225 44.6984 135.405 45.1319 135.405 45.5839C135.405 46.0359 135.225 46.4693 134.905 46.7889C134.585 47.1086 134.151 47.2881 133.698 47.2881Z"
          fill="#AFAFE7"
        />
        <path
          d="M121.542 40.0452H110.239C109.787 40.0452 109.353 39.8657 109.033 39.5461C108.713 39.2265 108.533 38.793 108.533 38.341C108.533 37.889 108.713 37.4556 109.033 37.136C109.353 36.8164 109.787 36.6368 110.239 36.6368H121.542C121.995 36.6368 122.429 36.8164 122.749 37.136C123.069 37.4556 123.248 37.889 123.248 38.341C123.248 38.793 123.069 39.2265 122.749 39.5461C122.429 39.8657 121.995 40.0452 121.542 40.0452Z"
          fill="#AFAFE7"
        />
        <path
          d="M156.085 64.3302H120.256C119.803 64.3302 119.369 64.1506 119.05 63.831C118.73 63.5114 118.55 63.0779 118.55 62.626C118.55 62.174 118.73 61.7405 119.05 61.4209C119.369 61.1013 119.803 60.9218 120.256 60.9218H156.085C156.537 60.9218 156.971 61.1013 157.291 61.4209C157.611 61.7405 157.791 62.174 157.791 62.626C157.791 63.0779 157.611 63.5114 157.291 63.831C156.971 64.1506 156.537 64.3302 156.085 64.3302Z"
          fill="#F0F0F0"
        />
        <path
          d="M156.085 72.2121H120.256C119.803 72.2121 119.369 72.0326 119.05 71.713C118.73 71.3934 118.55 70.9599 118.55 70.5079C118.55 70.0559 118.73 69.6225 119.05 69.3029C119.369 68.9833 119.803 68.8037 120.256 68.8037H156.085C156.537 68.8037 156.971 68.9833 157.291 69.3029C157.611 69.6225 157.791 70.0559 157.791 70.5079C157.791 70.9599 157.611 71.3934 157.291 71.713C156.971 72.0326 156.537 72.2121 156.085 72.2121Z"
          fill="#F0F0F0"
        />
        <path
          d="M49.7328 74.2044C49.5111 74.204 49.2964 74.1271 49.125 73.9868C48.9535 73.8464 48.8359 73.6512 48.7921 73.4341L48.0161 69.57C47.6045 67.5204 48.0249 65.3914 49.1849 63.6514C50.3448 61.9115 52.1492 60.703 54.2011 60.2919C56.2531 59.8808 58.3845 60.3008 60.1264 61.4594C61.8684 62.618 63.0782 64.4204 63.4897 66.47L64.2655 70.3341C64.3153 70.5834 64.2641 70.8421 64.1231 71.0537C63.9821 71.2652 63.7629 71.4122 63.5135 71.4625L49.9219 74.1856C49.8597 74.198 49.7963 74.2044 49.7328 74.2044Z"
          fill="#2F2E41"
        />
        <path
          d="M73.8618 131.739L71.4237 131.738L70.2644 122.345L73.8626 122.345L73.8618 131.739Z"
          fill="#A0616A"
        />
        <path
          d="M69.6822 131.042H74.3843V134H66.7217C66.7217 133.215 67.0336 132.463 67.5888 131.909C68.144 131.354 68.897 131.042 69.6822 131.042Z"
          fill="#2F2E41"
        />
        <path
          d="M48.4577 131.714L46.2126 130.764L48.8119 121.663L52.1252 123.065L48.4577 131.714Z"
          fill="#A0616A"
        />
        <path
          d="M44.8808 129.445L49.2105 131.277L48.0559 134L41 131.015C41.1517 130.657 41.3723 130.333 41.6494 130.061C41.9265 129.788 42.2546 129.573 42.615 129.427C42.9753 129.281 43.3609 129.208 43.7497 129.211C44.1384 129.214 44.5228 129.294 44.8808 129.445Z"
          fill="#2F2E41"
        />
        <path
          d="M55.9763 73.2361C58.8692 73.2361 61.2144 70.8936 61.2144 68.0039C61.2144 65.1143 58.8692 62.7718 55.9763 62.7718C53.0834 62.7718 50.7383 65.1143 50.7383 68.0039C50.7383 70.8936 53.0834 73.2361 55.9763 73.2361Z"
          fill="#A0616A"
        />
        <path
          d="M56.2243 91.8057L56.1705 91.6313C54.7432 86.9971 53.2679 82.2071 51.7905 77.6514L51.7505 77.5285L51.8413 77.4363C54.1345 75.1061 58.4306 74.1766 61.8347 75.2749C65.2606 76.3796 67.6215 79.4782 67.3263 82.4826C67.1694 84.2875 67.6548 86.0898 68.6972 87.5724L68.8339 87.7704L68.6202 87.882C64.7932 89.8491 60.6651 91.1662 56.4051 91.7794L56.2243 91.8057Z"
          fill="#3F3D56"
        />
        <path
          d="M49.9069 130.026C49.7201 130.026 49.5367 129.976 49.3751 129.883L46.5457 128.251C46.3136 128.118 46.14 127.903 46.0597 127.649C45.9794 127.394 45.9984 127.119 46.1127 126.877L52.3999 111.976L57.205 90.2419L65.9619 87.0613L66.0415 87.0979C75.4299 91.4056 74.7041 122.282 74.4633 128.428C74.4525 128.687 74.3479 128.933 74.169 129.12C73.9901 129.307 73.7492 129.423 73.4911 129.446L70.8579 129.789C70.5902 129.813 70.3233 129.736 70.1098 129.572C69.8964 129.409 69.7517 129.172 69.7043 128.908L64.3285 104.91C64.3231 104.864 64.3031 104.822 64.2715 104.788C64.2399 104.755 64.1983 104.733 64.1531 104.725C64.1097 104.717 64.0648 104.722 64.0248 104.741C63.9848 104.76 63.9519 104.791 63.9308 104.83L50.8394 129.48C50.7704 129.604 50.6773 129.713 50.5656 129.8C50.4539 129.887 50.3259 129.951 50.189 129.988C50.0971 130.013 50.0022 130.026 49.9069 130.026Z"
          fill="#2F2E41"
        />
        <path
          d="M53.052 108.126C53.2173 107.885 53.3278 107.611 53.3756 107.323C53.4234 107.035 53.4074 106.74 53.3287 106.458C53.25 106.177 53.1106 105.916 52.9201 105.695C52.7297 105.473 52.493 105.296 52.2266 105.176L52.3698 100.613L49.6152 99.8502L49.5036 106.296C49.331 106.757 49.3334 107.264 49.5104 107.723C49.6875 108.182 50.0268 108.56 50.4642 108.785C50.9016 109.011 51.4066 109.068 51.8835 108.947C52.3605 108.825 52.7762 108.533 53.052 108.126Z"
          fill="#A0616A"
        />
        <path
          d="M49.5443 103.303L49.3826 103.302C49.1162 103.3 48.8602 103.198 48.6656 103.017C48.471 102.835 48.3522 102.586 48.3327 102.321C48.0685 98.9229 47.2572 87.3481 47.9106 81.0727C47.9971 80.4871 48.2005 79.9248 48.5086 79.4191C48.8167 78.9134 49.2233 78.4747 49.7043 78.1289C50.1853 77.783 50.7309 77.5372 51.3088 77.4058C51.8867 77.2745 52.485 77.2603 53.0685 77.3642L53.2367 77.4013L53.2354 77.5735C53.1755 85.83 53.1794 94.0383 53.1831 101.976C53.1837 102.219 53.1015 102.454 52.9501 102.644C52.7986 102.833 52.5871 102.965 52.3503 103.018C51.4272 103.209 50.4869 103.304 49.5443 103.303Z"
          fill="#AFAFE7"
        />
        <path
          d="M55.8149 113.205C55.1098 113.202 54.43 112.942 53.903 112.474C53.376 112.006 53.0379 111.363 52.9522 110.663L53.1842 91.608L49.3925 82.0283C49.233 80.7698 49.504 79.4944 50.1615 78.4092C50.8191 77.3239 51.8246 76.4926 53.0148 76.0501L54.0284 75.6703C54.22 75.5986 54.4238 75.5653 54.6282 75.5725C54.8327 75.5796 55.0337 75.627 55.2197 75.712C55.4058 75.7969 55.5732 75.9177 55.7124 76.0674C55.8516 76.2171 55.9599 76.3928 56.0309 76.5844L59.9407 87.1287L59.9417 87.1441C60.2128 91.3407 61.4364 112.228 58.9968 112.851C57.963 113.093 56.9043 113.212 55.8426 113.205L55.8149 113.205Z"
          fill="#AFAFE7"
        />
        <path
          d="M78.3394 100.932C78.0685 100.822 77.825 100.654 77.626 100.44C77.427 100.226 77.2774 99.9712 77.1877 99.6933C77.0981 99.4153 77.0705 99.1211 77.107 98.8314C77.1434 98.5416 77.2431 98.2634 77.3988 98.0163L74.7598 94.2896L76.6358 92.1353L80.2887 97.4504C80.687 97.7393 80.9653 98.1641 81.071 98.6443C81.1767 99.1244 81.1023 99.6267 80.862 100.056C80.6218 100.485 80.2323 100.811 79.7673 100.972C79.3024 101.134 78.7943 101.119 78.3394 100.932Z"
          fill="#A0616A"
        />
        <path
          d="M75.7041 96.1423C75.5663 96.1382 75.4315 96.1011 75.311 96.0341C75.1905 95.967 75.0879 95.8721 75.0118 95.7572L74.998 95.7362C70.6313 89.1345 66.116 82.3081 61.5397 75.503L61.4905 75.4296L61.5535 75.3676C61.9548 74.9934 62.4279 74.7041 62.944 74.5171C63.4602 74.3301 64.0089 74.2494 64.5572 74.2796C65.1216 74.2899 65.6774 74.4189 66.1885 74.6584C66.6996 74.8978 67.1543 75.2423 67.5231 75.6692C71.5221 80.5295 77.2296 90.6171 78.8835 93.5932C78.9879 93.778 79.0188 93.9953 78.9701 94.2018C78.9213 94.4083 78.7964 94.5889 78.6204 94.7076C77.8083 95.2599 76.9383 95.7222 76.0259 96.0863C75.9236 96.1264 75.8141 96.1455 75.7041 96.1423Z"
          fill="#AFAFE7"
        />
        <path
          d="M74.2381 108.958C71.6226 108.96 62.2645 90.9613 60.4152 87.3531L60.3992 87.3095L58.3533 76.2687C58.3161 76.0678 58.319 75.8615 58.3617 75.6617C58.4045 75.4619 58.4864 75.2726 58.6026 75.1044C58.7189 74.9363 58.8673 74.7928 59.0392 74.6821C59.2111 74.5714 59.4032 74.4957 59.6045 74.4594L60.67 74.2669C61.9192 74.0382 63.209 74.238 64.3302 74.8338C65.4514 75.4296 66.338 76.3864 66.8462 77.549L69.5194 88.7728L78.458 103.943C78.7516 104.593 78.7947 105.328 78.5792 106.008C78.3637 106.687 77.9046 107.264 77.29 107.626C76.3815 108.175 75.4111 108.615 74.3991 108.936C74.3468 108.951 74.2926 108.958 74.2381 108.958Z"
          fill="#AFAFE7"
        />
        <path
          d="M60.0446 68.0492H49.4879V66.7711C49.4895 65.3591 50.0517 64.0054 51.0513 63.007C52.0508 62.0086 53.406 61.447 54.8196 61.4454H55.6726C57.0862 61.447 58.4414 62.0086 59.441 63.007C60.4405 64.0054 61.0027 65.3591 61.0043 66.7711V67.0906C61.004 67.3447 60.9028 67.5884 60.7229 67.7681C60.543 67.9478 60.2991 68.0489 60.0446 68.0492Z"
          fill="#2F2E41"
        />
      </g>
      <defs>
        <clipPath id="clip0_208_296">
          <rect
            width="158"
            height="109"
            fill="white"
            transform="translate(41 25)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};
