import { access } from "fs";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { networkService } from "~/services";
import Loader from "~/shared/components/ui/customLoader";
import { LOGOUT_API } from "~/shared/constants/api";
import { handleError } from "~/shared/utils/errors.util";
import useAuthStore from "~/store/auth.store";
import useWarningModalStore from "~/store/warning.store";

const Logout = () => {
  const navigate = useNavigate();
  const [accessToken, setAuthState] = useAuthStore((state) => [
    state.accessToken,
    state.setAuthState,
  ]);
  const [clearAllWarningState] = useWarningModalStore((state) => [
    state.clearAllWarningState,
  ]);

  useEffect(() => {
    const handleLogout = async () => {
      if (!accessToken) return;
      try {
        await networkService.post(LOGOUT_API, {});
        setAuthState();
        clearAllWarningState();
      } catch (error: any) {
        handleError({ error: error, message: error.message, level: "error" });
      }
    };
    handleLogout();
  }, [navigate, setAuthState, accessToken, clearAllWarningState]);

  return (
    <div className="h-screen w-full">
      <h1 className="text-lg font-space-grotesk font-base text-center h-full p-2 flex items-center justify-center">
        <span className="mb-32">Logging you out</span>
      </h1>
      <Loader />
    </div>
  );
};

export default Logout;
