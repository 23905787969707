export const EducationAppIcon = () => {
  return (
    <svg
      width="240"
      height="160"
      viewBox="0 0 240 160"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M232.674 15.7744H7.32594C7.23939 15.7741 7.15649 15.7395 7.0954 15.6782C7.03431 15.6169 7 15.5339 7 15.4473C7 15.3608 7.03431 15.2778 7.0954 15.2165C7.15649 15.1552 7.23939 15.1206 7.32594 15.1203H232.674C232.761 15.1206 232.844 15.1552 232.905 15.2165C232.966 15.2778 233 15.3608 233 15.4473C233 15.5339 232.966 15.6169 232.905 15.6782C232.844 15.7395 232.761 15.7741 232.674 15.7744Z"
        fill="#CACACA"
      />
      <path
        d="M14.6876 11.1954C16.6745 11.1954 18.2853 9.58468 18.2853 7.59772C18.2853 5.61075 16.6745 4 14.6876 4C12.7006 4 11.0898 5.61075 11.0898 7.59772C11.0898 9.58468 12.7006 11.1954 14.6876 11.1954Z"
        fill="#3F3D56"
      />
      <path
        d="M27.1159 11.1954C29.1029 11.1954 30.7136 9.58468 30.7136 7.59772C30.7136 5.61075 29.1029 4 27.1159 4C25.1289 4 23.5182 5.61075 23.5182 7.59772C23.5182 9.58468 25.1289 11.1954 27.1159 11.1954Z"
        fill="#3F3D56"
      />
      <path
        d="M39.5445 11.1954C41.5315 11.1954 43.1422 9.58468 43.1422 7.59772C43.1422 5.61075 41.5315 4 39.5445 4C37.5575 4 35.9468 5.61075 35.9468 7.59772C35.9468 9.58468 37.5575 11.1954 39.5445 11.1954Z"
        fill="#3F3D56"
      />
      <path
        d="M225.228 6.20062H216.397C216.223 6.20062 216.057 6.1317 215.934 6.00903C215.812 5.88636 215.743 5.71998 215.743 5.54649C215.743 5.37301 215.812 5.20662 215.934 5.08395C216.057 4.96128 216.223 4.89236 216.397 4.89236H225.228C225.401 4.89236 225.567 4.96128 225.69 5.08395C225.813 5.20662 225.882 5.37301 225.882 5.54649C225.882 5.71998 225.813 5.88636 225.69 6.00903C225.567 6.1317 225.401 6.20062 225.228 6.20062Z"
        fill="#3F3D56"
      />
      <path
        d="M225.228 8.65356H216.397C216.223 8.65356 216.057 8.58465 215.934 8.46198C215.812 8.3393 215.743 8.17292 215.743 7.99944C215.743 7.82595 215.812 7.65957 215.934 7.5369C216.057 7.41423 216.223 7.34531 216.397 7.34531H225.228C225.401 7.34531 225.567 7.41423 225.69 7.5369C225.813 7.65957 225.882 7.82595 225.882 7.99944C225.882 8.17292 225.813 8.3393 225.69 8.46198C225.567 8.58465 225.401 8.65356 225.228 8.65356Z"
        fill="#3F3D56"
      />
      <path
        d="M225.228 11.1066H216.397C216.223 11.1066 216.057 11.0377 215.934 10.915C215.812 10.7923 215.743 10.626 215.743 10.4525C215.743 10.279 215.812 10.1126 215.934 9.98993C216.057 9.86725 216.223 9.79834 216.397 9.79834H225.228C225.401 9.79894 225.566 9.86812 225.689 9.99072C225.811 10.1133 225.879 10.2794 225.879 10.4525C225.879 10.6256 225.811 10.7916 225.689 10.9142C225.566 11.0368 225.401 11.106 225.228 11.1066Z"
        fill="#3F3D56"
      />
      <path
        d="M160.469 29.7996H10.9996V119.415H160.469V29.7996Z"
        fill="#F0F0F0"
      />
      <path
        d="M225.882 29.7996H178.784V58.0374H225.882V29.7996Z"
        fill="#F0F0F0"
      />
      <path
        d="M216.485 71.0098H188.463C188.113 71.0098 187.777 70.8707 187.529 70.6232C187.282 70.3757 187.143 70.04 187.143 69.6899C187.143 69.3398 187.282 69.0041 187.529 68.7566C187.777 68.509 188.113 68.37 188.463 68.37H216.485C216.836 68.37 217.171 68.509 217.419 68.7566C217.666 69.0041 217.805 69.3398 217.805 69.6899C217.805 70.04 217.666 70.3757 217.419 70.6232C217.171 70.8707 216.836 71.0098 216.485 71.0098Z"
        fill="#E4E4E4"
      />
      <path
        d="M211.967 66.4916H192.981C192.631 66.4916 192.295 66.3526 192.047 66.105C191.8 65.8575 191.661 65.5218 191.661 65.1717C191.661 64.8216 191.8 64.4859 192.047 64.2384C192.295 63.9908 192.631 63.8518 192.981 63.8518H211.967C212.317 63.8518 212.653 63.9908 212.901 64.2384C213.148 64.4859 213.287 64.8216 213.287 65.1717C213.287 65.5218 213.148 65.8575 212.901 66.105C212.653 66.3526 212.317 66.4916 211.967 66.4916Z"
        fill="#E4E4E4"
      />
      <path
        d="M225.882 81.1488H178.784V109.387H225.882V81.1488Z"
        fill="#F0F0F0"
      />
      <path
        d="M216.485 122.359H188.463C188.113 122.359 187.777 122.22 187.529 121.972C187.282 121.725 187.143 121.389 187.143 121.039C187.143 120.689 187.282 120.353 187.529 120.106C187.777 119.858 188.113 119.719 188.463 119.719H216.485C216.836 119.719 217.171 119.858 217.419 120.106C217.666 120.353 217.805 120.689 217.805 121.039C217.805 121.389 217.666 121.725 217.419 121.972C217.171 122.22 216.836 122.359 216.485 122.359Z"
        fill="#E4E4E4"
      />
      <path
        d="M211.967 117.841H192.981C192.631 117.841 192.295 117.702 192.047 117.454C191.8 117.207 191.661 116.871 191.661 116.521C191.661 116.171 191.8 115.835 192.047 115.588C192.295 115.34 192.631 115.201 192.981 115.201H211.967C212.317 115.202 212.652 115.341 212.899 115.588C213.146 115.836 213.285 116.171 213.285 116.521C213.285 116.871 213.146 117.206 212.899 117.454C212.652 117.701 212.317 117.84 211.967 117.841Z"
        fill="#E4E4E4"
      />
      <path
        d="M88.3314 78.0094C94.4915 78.0094 99.4852 73.0157 99.4852 66.8556C99.4852 60.6955 94.4915 55.7018 88.3314 55.7018C82.1713 55.7018 77.1776 60.6955 77.1776 66.8556C77.1776 73.0157 82.1713 78.0094 88.3314 78.0094Z"
        fill="#9E616A"
      />
      <path
        d="M107.124 119.415H79.1569L80.7265 84.05L80.9197 83.8471L83.6966 80.9165H92.5274L95.8045 84.089L96.0628 84.341L103.036 91.0228L107.124 119.415Z"
        fill="#AFAFE7"
      />
      <path
        d="M86.8984 119.415H65.6424C65.5018 105.882 65.3905 95.6705 65.3905 95.6705L66.4765 95.0099L66.483 95.0066L78.4044 87.7686L80.9066 83.7032L80.9197 83.8471L81.6556 92.4162C81.6556 92.4162 85.6555 105.339 86.8984 119.415Z"
        fill="#2F2E41"
      />
      <path
        d="M115.821 119.415H96.9067C95.896 114.83 95.3695 110.464 95.5985 106.823C96.5273 91.9518 94.3851 83.7032 95.3139 83.7032L95.8045 84.0891L101.639 88.6975L109.322 91.5135L114.653 93.4694L115.582 93.8095V94.2576C115.585 96.5078 115.608 106.958 115.821 119.415Z"
        fill="#2F2E41"
      />
      <path
        d="M77.7793 70.3453C77.7793 70.3453 78.727 68.681 78.5067 63.1213C78.2864 57.5617 86.7524 60.7062 93.8267 60.1758C100.901 59.6454 99.4035 69.4578 99.4035 69.4578C99.4035 69.4578 99.9139 68.8745 101.007 64.1388C102.099 59.403 99.6183 54.6837 99.6183 54.6837C98.8876 49.7073 94.9497 51.3022 94.9497 51.3022C96.8461 52.0405 96.4819 53.6191 96.4819 53.6191C94.8766 50.8046 90.9387 52.3995 90.9387 52.3995C85.6867 49.1037 80.3638 53.4438 80.3638 53.4438C74.0191 54.8838 77.74 59.9295 77.74 59.9295C71.3593 63.154 77.7793 70.3453 77.7793 70.3453Z"
        fill="#2F2E41"
      />
      <path
        d="M59.4839 119.415C60.0267 108.56 60.4421 100.043 60.2786 99.386C59.8207 97.5577 66.2802 95.0818 66.4765 95.0099L66.4797 95.0066H66.483L67.7161 95.6705L68.6187 119.415H59.4839Z"
        fill="#2F2E41"
      />
      <path
        d="M120.269 119.415H110.67L107.922 92.2789L109.054 91.4514C109.054 91.4514 109.152 91.4743 109.322 91.5135C110.202 91.7327 113.041 92.4751 114.653 93.4694C115.01 93.6709 115.325 93.9383 115.582 94.2576C115.739 94.4455 115.818 94.6869 115.801 94.9314C115.719 95.7 117.813 106.565 120.269 119.415Z"
        fill="#2F2E41"
      />
      <path
        d="M136.429 130.372H35.0389C34.6489 130.373 34.2752 130.528 33.9997 130.804C33.7242 131.08 33.5695 131.454 33.5695 131.844C33.5695 132.234 33.7242 132.608 33.9997 132.884C34.2752 133.16 34.6489 133.315 35.0389 133.316H136.429C136.623 133.316 136.814 133.278 136.993 133.204C137.172 133.131 137.335 133.022 137.471 132.886C137.608 132.749 137.717 132.586 137.791 132.408C137.865 132.229 137.903 132.037 137.903 131.844C137.903 131.65 137.865 131.459 137.791 131.28C137.717 131.101 137.608 130.939 137.471 130.802C137.335 130.666 137.172 130.557 136.993 130.484C136.814 130.41 136.623 130.372 136.429 130.372Z"
        fill="#CACACA"
      />
      <path
        d="M108.956 130.372V133.316H35.0389C34.6489 133.315 34.2752 133.16 33.9997 132.884C33.7242 132.608 33.5695 132.234 33.5695 131.844C33.5695 131.454 33.7242 131.08 33.9997 130.804C34.2752 130.528 34.6489 130.373 35.0389 130.372H108.956Z"
        fill="#3F3D56"
      />
      <path
        d="M108.956 134.788C110.582 134.788 111.899 133.47 111.899 131.844C111.899 130.218 110.582 128.9 108.956 128.9C107.33 128.9 106.012 130.218 106.012 131.844C106.012 133.47 107.33 134.788 108.956 134.788Z"
        fill="#3F3D56"
      />
      <path
        d="M85.7342 156.374C84.5324 156.374 83.3576 156.018 82.3583 155.35C81.3591 154.682 80.5803 153.733 80.1204 152.623C79.6605 151.513 79.5401 150.291 79.7746 149.112C80.009 147.933 80.5878 146.851 81.4376 146.001C82.2873 145.151 83.37 144.572 84.5487 144.338C85.7274 144.104 86.9492 144.224 88.0595 144.684C89.1698 145.144 90.1188 145.922 90.7864 146.922C91.4541 147.921 91.8105 149.096 91.8105 150.298C91.8087 151.909 91.1679 153.453 90.0287 154.592C88.8896 155.731 87.3451 156.372 85.7342 156.374ZM85.7342 144.689C84.6248 144.689 83.5404 145.018 82.618 145.634C81.6956 146.25 80.9767 147.126 80.5522 148.151C80.1277 149.176 80.0166 150.304 80.233 151.392C80.4494 152.48 80.9836 153.479 81.7681 154.264C82.5525 155.048 83.5519 155.582 84.6399 155.799C85.7279 156.015 86.8557 155.904 87.8806 155.48C88.9055 155.055 89.7815 154.336 90.3978 153.414C91.0141 152.491 91.3431 151.407 91.3431 150.298C91.3414 148.811 90.7499 147.385 89.6984 146.333C88.6469 145.282 87.2212 144.69 85.7342 144.689Z"
        fill="#AFAFE7"
      />
      <path
        d="M84.9316 153.569C84.8696 153.569 84.8102 153.545 84.7663 153.501C84.7225 153.457 84.6979 153.398 84.6979 153.336V147.259C84.6976 147.229 84.7034 147.198 84.715 147.169C84.7266 147.141 84.7438 147.115 84.7655 147.093C84.7872 147.071 84.8131 147.053 84.8416 147.041C84.8701 147.03 84.9007 147.023 84.9316 147.023C84.9625 147.023 84.9931 147.03 85.0216 147.041C85.0501 147.053 85.076 147.071 85.0977 147.093C85.1194 147.115 85.1366 147.141 85.1482 147.169C85.1598 147.198 85.1656 147.229 85.1653 147.259V153.336C85.1653 153.398 85.1407 153.457 85.0969 153.501C85.053 153.545 84.9936 153.569 84.9316 153.569Z"
        fill="#AFAFE7"
      />
      <path
        d="M86.5366 153.569C86.4746 153.569 86.4151 153.545 86.3713 153.501C86.3275 153.457 86.3029 153.398 86.3029 153.336V147.259C86.3026 147.229 86.3084 147.198 86.32 147.169C86.3317 147.141 86.3488 147.115 86.3705 147.093C86.3923 147.071 86.4181 147.053 86.4466 147.042C86.4751 147.03 86.5057 147.024 86.5366 147.024C86.5674 147.024 86.598 147.03 86.6265 147.042C86.655 147.053 86.6809 147.071 86.7026 147.093C86.7243 147.115 86.7415 147.141 86.7531 147.169C86.7647 147.198 86.7706 147.229 86.7703 147.259V153.336C86.7703 153.398 86.7457 153.457 86.7018 153.501C86.658 153.545 86.5986 153.569 86.5366 153.569Z"
        fill="#AFAFE7"
      />
      <path
        d="M57.9032 150.298C57.9032 149.096 58.2596 147.921 58.9272 146.922C59.5949 145.922 60.5439 145.144 61.6542 144.684C62.7645 144.224 63.9863 144.104 65.1649 144.338C66.3436 144.572 67.4263 145.151 68.2761 146.001C69.1259 146.851 69.7046 147.933 69.9391 149.112C70.1735 150.291 70.0532 151.513 69.5933 152.623C69.1334 153.733 68.3546 154.682 67.3553 155.35C66.3561 156.018 65.1813 156.374 63.9795 156.374C62.3685 156.372 60.8241 155.731 59.6849 154.592C58.5458 153.453 57.905 151.909 57.9032 150.298ZM58.3706 150.298C58.3706 151.407 58.6996 152.491 59.3159 153.414C59.9322 154.336 60.8082 155.055 61.8331 155.48C62.858 155.904 63.9858 156.015 65.0738 155.799C66.1618 155.582 67.1612 155.048 67.9456 154.264C68.7301 153.479 69.2643 152.48 69.4807 151.392C69.6971 150.304 69.586 149.176 69.1615 148.151C68.737 147.126 68.018 146.25 67.0957 145.634C66.1733 145.018 65.0888 144.689 63.9795 144.689C62.4925 144.69 61.0668 145.282 60.0153 146.333C58.9638 147.385 58.3723 148.811 58.3706 150.298Z"
        fill="#3F3D56"
      />
      <path
        d="M66.6039 152.343C66.6467 152.343 66.6883 152.329 66.7223 152.303C66.7563 152.277 66.7807 152.241 66.7918 152.199C66.8029 152.158 66.8001 152.114 66.7837 152.075C66.7673 152.035 66.7384 152.002 66.7013 151.981L63.7861 150.298L66.7013 148.614C66.7459 148.589 66.7785 148.546 66.7919 148.496C66.8052 148.447 66.7982 148.394 66.7725 148.349C66.7467 148.304 66.7042 148.272 66.6545 148.258C66.6047 148.245 66.5516 148.252 66.5069 148.278L63.3002 150.129C63.2706 150.146 63.2461 150.171 63.229 150.2C63.212 150.23 63.203 150.263 63.203 150.298C63.203 150.332 63.212 150.365 63.229 150.395C63.2461 150.424 63.2706 150.449 63.3002 150.466L66.5069 152.317C66.5364 152.334 66.5699 152.343 66.6039 152.343Z"
        fill="#3F3D56"
      />
      <path
        d="M62.3041 153.018C62.3556 153.018 62.4051 152.998 62.4415 152.962C62.478 152.925 62.4984 152.876 62.4984 152.824V147.771C62.4984 147.719 62.478 147.67 62.4415 147.634C62.4051 147.597 62.3556 147.577 62.3041 147.577C62.2525 147.577 62.2031 147.597 62.1667 147.634C62.1302 147.67 62.1097 147.719 62.1097 147.771V152.824C62.1097 152.876 62.1302 152.925 62.1667 152.962C62.2031 152.998 62.2525 153.018 62.3041 153.018Z"
        fill="#3F3D56"
      />
      <path
        d="M107.489 156.374C106.287 156.374 105.112 156.018 104.113 155.35C103.114 154.682 102.335 153.733 101.875 152.623C101.415 151.513 101.295 150.291 101.529 149.112C101.764 147.933 102.342 146.851 103.192 146.001C104.042 145.151 105.125 144.572 106.303 144.338C107.482 144.104 108.704 144.224 109.814 144.684C110.924 145.144 111.873 145.922 112.541 146.922C113.209 147.921 113.565 149.096 113.565 150.298C113.563 151.909 112.922 153.453 111.783 154.592C110.644 155.731 109.1 156.372 107.489 156.374ZM107.489 144.689C106.379 144.689 105.295 145.018 104.373 145.634C103.45 146.25 102.731 147.126 102.307 148.151C101.882 149.176 101.771 150.304 101.988 151.392C102.204 152.48 102.738 153.479 103.523 154.264C104.307 155.048 105.306 155.582 106.394 155.799C107.482 156.015 108.61 155.904 109.635 155.48C110.66 155.055 111.536 154.336 112.152 153.414C112.769 152.491 113.098 151.407 113.098 150.298C113.096 148.81 112.504 147.385 111.453 146.333C110.401 145.282 108.976 144.69 107.489 144.689Z"
        fill="#3F3D56"
      />
      <path
        d="M104.864 152.343C104.822 152.343 104.78 152.329 104.746 152.303C104.712 152.277 104.688 152.241 104.676 152.199C104.665 152.158 104.668 152.114 104.685 152.075C104.701 152.035 104.73 152.002 104.767 151.981L107.682 150.298L104.767 148.614C104.722 148.589 104.69 148.546 104.677 148.496C104.664 148.447 104.671 148.394 104.696 148.349C104.722 148.305 104.764 148.272 104.814 148.259C104.864 148.245 104.917 148.252 104.961 148.278L108.168 150.129C108.198 150.146 108.222 150.171 108.239 150.2C108.256 150.23 108.265 150.263 108.265 150.298C108.265 150.332 108.256 150.365 108.239 150.395C108.222 150.424 108.198 150.449 108.168 150.466L104.961 152.317C104.932 152.334 104.898 152.343 104.864 152.343Z"
        fill="#3F3D56"
      />
      <path
        d="M109.164 153.018C109.112 153.018 109.063 152.998 109.027 152.962C108.99 152.925 108.97 152.876 108.97 152.824V147.771C108.97 147.719 108.99 147.67 109.027 147.634C109.063 147.597 109.112 147.577 109.164 147.577C109.215 147.577 109.265 147.597 109.301 147.634C109.338 147.67 109.358 147.719 109.358 147.771V152.824C109.358 152.876 109.338 152.925 109.301 152.962C109.265 152.998 109.215 153.018 109.164 153.018Z"
        fill="#3F3D56"
      />
    </svg>
  );
};
