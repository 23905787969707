import { Link } from 'react-router-dom';
import ManufacturerTable from '~/shared/components/manufacturerTable';
import PageHeader from '~/shared/components/page-header.component';
import useSidebarStore from '~/store/sidebar.store';

export default function Manufacturer() {

    const [collapsed] = useSidebarStore(state => [state.collapsed]);

    return (
        <div className={`flex flex-col flex-grow p-8 overflow-hidden ${collapsed ? "ml-20" : "ml-72"} transition-all duration-300`}>
            <PageHeader title="Manufacturer" />
            <div className="flex justify-end mt-4">
                <Link to="/manufacturer/add-manufacturer">
                    <button
                        className="py-2 px-4 md:py-2 md:px-4 lg:py-2 lg:px-6 rounded-full border border-[#AFAFE7] text-sm md:text-base lg:text-base font-space-grotesk"
                    >
                        + Add Manufacturer
                    </button>
                </Link>
            </div>

            <div className="mt-8">
                <ManufacturerTable />
            </div>
        </div>

    );
}