import React, { useState } from "react";
import { ErrorMessage, Field, Form, Formik } from "formik";
import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import * as Yup from "yup";
import networkService from "~/services/network.service";
import BgImage from "~/shared/components/bgImage";
import { CardDescription, CardHeader } from "~/shared/components/ui/card";
import { Label } from "~/shared/components/ui/label";
import { UserRole } from "~/shared/config";
import { PROFILE_INFO_API, VERIFY_EMAIL_API } from "~/shared/constants/api";
import { BUTTON_TEXT_SEND } from "~/shared/constants/label";
import useAuthStore from "~/store/auth.store";
import { handleError } from "~/shared/utils/errors.util";

const VerifyEmail: React.FC = () => {
  const { user } = useAuthStore((state) => ({
    user: state.user,
  }));
  const [userData, setUserData] = useState<any>();
  const navigate = useNavigate();
  const { token } = useParams();

  useEffect(() => {
    const getUser = async () => {
      try {
        const url = `${PROFILE_INFO_API}/${user.userId}`;
        const response = await networkService.get<any>(url);
        setUserData(response.data);
      } catch (error: any) {
        handleError({ error: error, message: error.message, level: "error" });
      }
    };

    getUser();
  }, [user]);

  useEffect(() => {
    if (!token) return;
    const verifyToken = async (token: string) => {
      try {
        const tokenVerificationResponse = networkService.post<any>(
          `${VERIFY_EMAIL_API}/${token}`,
          {}
        );
        if (tokenVerificationResponse) {
          user?.role === UserRole.Admin
            ? navigate("/home")
            : navigate("/dashboard");
          toast.success("Email verified successfully");
        }
      } catch (error: any) {
        handleError({ error: error, message: error.message, level: "error" });
      }
    };
    verifyToken(token);
  }, [token, user, navigate]);

  const sendVerificationLink = async (email: string) => {
    try {
      const verificationResponse = await networkService.post<any>(
        `${PROFILE_INFO_API}/request-email-verification`,
        {
          email: email,
        }
      );
      if (verificationResponse) {
        user?.role === UserRole.Admin
          ? navigate("/home")
          : navigate("/dashboard");
        toast.success("Verification mail sent successfully");
      }
    } catch (error: any) {
      handleError({ error: error, message: error.message, level: "error" });
    }
  };

  // Validation schema using Yup
  const validationSchema = Yup.object({
    email: Yup.string()
      .email("Invalid email address")
      .required("Email is required"),
  });

  return (
    <div className="flex min-h-screen font-space-grotesk">
      <BgImage />
      <div className="flex flex-1 flex-col justify-start items-start px-6 pt-8 lg:px-8 z-10 absolute top-40 left-24">
        <div className="flex flex-1 flex-col justify-start items-start px-6 pt-10 lg:px-8">
          <CardHeader className="mt-8 text-black font-sans text-4xl font-bold leading-tight">
            Welcome!
          </CardHeader>
          <CardDescription className="mt-0 ml-6 text-black text-lg font-opensans font-normal leading-[21px]">
            Please enter your email address to receive a verification link.
          </CardDescription>

          <div className="mt-6 ml-6 flex flex-col flex-1 min-w-[500px]">
            <Formik
              enableReinitialize
              initialValues={{ email: userData ? userData.email : "" }}
              validationSchema={validationSchema}
              onSubmit={(values) => {
                sendVerificationLink(values.email);
              }}>
              {({ handleChange, values }) => (
                <Form className="space-y-6 flex-1">
                  <div className="flex mt-auto w-full max-w-md flex-col items-start flex-shrink-0">
                    <Label className="text-[#1B1B20] font-space-grotesk text-base not-italic font-normal leading-normal mb-4">
                      Email
                    </Label>
                    <div className="w-full">
                      <Field
                        name="email"
                        type="email"
                        className="px-4 bg-transparent py-2 w-full rounded-[1rem] border-[1px] border-[#D0D0D6] placeholder-gray input-text focus:outline-none"
                        placeholder="Enter your email address"
                        value={values.email}
                        onChange={handleChange}
                      />
                      <ErrorMessage
                        name="email"
                        component="div"
                        className="text-red-600 text-sm mt-1"
                      />
                    </div>
                  </div>
                  <div className="flex gap-3">
                    <div className="flex w-1/2 max-w-md gap-10 justify-center items-center rounded-full bg-[#E9C3E2]">
                      <button
                        type="submit"
                        className="border-none w-full py-2 rounded-[1rem]">
                        {BUTTON_TEXT_SEND}
                      </button>
                    </div>

                    <div className="flex w-1/2 max-w-md gap-10 justify-center items-center rounded-full bg-zinc-900 text-white-a700">
                      <button
                        type="button"
                        className="border-none w-full py-2 rounded-[1rem]"
                        onClick={() => {
                          navigate("/login");
                        }}>
                        Back to Home
                      </button>
                    </div>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </div>
    </div>
  );
};

export default VerifyEmail;
