import { ErrorMessage, Field, Form, Formik } from "formik";
import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import * as Yup from "yup";
import networkService from "~/services/network.service";
import BgImage from "~/shared/components/bgImage";
import { CardDescription, CardHeader } from "~/shared/components/ui/card";
import { Label } from "~/shared/components/ui/label";
import { UserRole } from "~/shared/config";
import { FORGOT_PASSWORD_API } from "~/shared/constants/api";
import { FORGOT_PASSWORD_ERROR } from "~/shared/constants/errormessage";
import {
  BUTTON_TEXT_SEND,
  BUTTON_TEXT_SENDING,
} from "~/shared/constants/label";
import { FORGOT_PASSWORD_SUCCESS } from "~/shared/constants/successmessage";
import { handleError } from "~/shared/utils/errors.util";
import useAuthStore from "~/store/auth.store";

const ForgotPassword = () => {
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const { user } = useAuthStore((state) => ({
    user: state.user,
  }));
  const navigate = useNavigate();

  useEffect(() => {
    if (!user) return;

    if (user?.role === UserRole.Admin) {
      navigate("/home");
    } else {
      if (user?.organizationId) {
        navigate("/dashboard");
      } else {
        navigate("/onboarding/organization");
      }
    }
  }, [user, navigate]);

  const handleForgotPassword = async (email: string) => {
    setLoading(true);
    try {
      const response = await networkService.post<any>(FORGOT_PASSWORD_API, {
        email,
      });
      if (response) {
        toast.success(FORGOT_PASSWORD_SUCCESS);
      }
    } catch (error: any) {
      handleError({ error: error, message: error.message, level: "error" });
      setError(FORGOT_PASSWORD_ERROR);
    } finally {
      setLoading(false);
    }
  };

  // Validation schema using Yup
  const validationSchema = Yup.object({
    email: Yup.string()
      .email("Invalid email address")
      .required("Email is required"),
  });

  return (
    <div className="flex min-h-screen font-space-grotesk">
      <BgImage />
      <div className="flex flex-1 flex-col justify-start items-start px-6 pt-8 lg:px-8 z-10 absolute top-40 left-24">
        <div className="flex flex-1 flex-col justify-start items-start px-6 pt-10 lg:px-8">
          <CardHeader className="mt-8 text-black font-sans text-4xl font-bold leading-tight">
            Welcome!
          </CardHeader>
          <CardDescription className="mt-0 ml-6 text-black text-lg font-opensans font-normal leading-[21px]">
            Forgot your password? Enter your email to reset it.
          </CardDescription>

          <div className="mt-6 ml-6 sm:mx-auto sm:w-full sm:max-w-sm flex flex-col flex-1">
            <Formik
              initialValues={{ email: "" }}
              validationSchema={validationSchema}
              onSubmit={(values) => {
                handleForgotPassword(values.email);
              }}>
              {({ handleChange, values }) => (
                <Form className="space-y-6 flex-1">
                  <div className="flex mt-auto w-full max-w-md flex-col items-start flex-shrink-0">
                    <Label className="text-[#1B1B20] font-space-grotesk text-base not-italic font-normal leading-normal mb-1">
                      Email
                    </Label>
                    <div className="w-full">
                      <Field
                        name="email"
                        type="email"
                        className="px-4 bg-transparent py-2 w-full rounded-[1rem] border-[1px] border-[#D0D0D6] placeholder-gray input-text focus:outline-none"
                        placeholder="Enter your email address"
                        value={values.email}
                        onChange={handleChange}
                      />
                      <ErrorMessage
                        name="email"
                        component="div"
                        className="text-red-600 text-sm mt-1"
                      />
                    </div>
                  </div>

                  <div className="flex w-full max-w-md gap-10 justify-center items-center rounded-[1rem] bg-[#E9C3E2]">
                    <button
                      type="submit"
                      className="border-none w-full py-1.5 rounded-[1rem]"
                      disabled={loading}>
                      {loading ? BUTTON_TEXT_SENDING : BUTTON_TEXT_SEND}
                    </button>
                  </div>
                  {error && <div className="text-red-600 text-sm">{error}</div>}
                </Form>
              )}
            </Formik>

            <div className="w-full flex justify-center my-4">
              <div
                className="w-full max-w-md border-b border-stroke rotate--0.215 flex-shrink-0"
                style={{ height: "0px" }}></div>
            </div>

            <div className="flex w-full justify-center mt-4">
              <Link
                to="/login"
                className="text-[#000] font-space-grotesk text-[16px] font-normal leading-[24px] underline">
                Remembered your password? Log in
              </Link>
            </div>

            <div className="w-[450px] flex justify-center mt-1 pb-4">
              <p className="text-xs text-gray-700">
                By continuing, you agree to Heartfocus’s{" "}
                <a
                  href="https://www.heartfocus.ai/portal-terms-conditions"
                  className="text-gray-700 underline hover:no-underline">
                  Terms of Service
                </a>{" "}
                and{" "}
                <a
                  href="https://www.heartfocus.ai/education-privacy-policy"
                  className="text-gray-700 underline hover:no-underline">
                  Privacy Policy
                </a>
                .
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ForgotPassword;
