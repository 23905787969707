import { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import networkService from "~/services/network.service";
import PageHeader from "~/shared/components/page-header.component";
import {
  PLAN_ON_LICENSE,
  SERVICE_ID,
  SERVICES_API,
} from "~/shared/constants/api";
import { handleError } from "~/shared/utils/errors.util";
import { formatDate } from "~/shared/utils/helper.util";
import useAuthStore from "~/store/auth.store";
import useSidebarStore from "~/store/sidebar.store";

// Define interfaces for the API response data
interface PricingModel {
  unitPrice: number;
  billingCycle: string;
  unit: string;
}

interface PlanDetails {
  name: string;
  description: string;
  trialPeriod: number;
  type: string;
  pricingModel: PricingModel;
  createdAt: string;
  updatedAt: string;
  customersCount: number; // Organization count
  probesCount: number; // Device count
  isActive: boolean;
}

interface License {
  subscription: {
    organization: {
      id: string;
    };
  };
}

// Define the component
export default function Example() {
  const [planDetails, setPlanDetails] = useState<PlanDetails | null>(null);
  const [licenseData, setLicenseData] = useState<License[]>([]); // Adjust based on actual structure
  const [collapsed] = useSidebarStore((state) => [state.collapsed]);

  const accessToken = useAuthStore((state) => state.accessToken);
  const serviceId = SERVICE_ID;
  const { planId } = useParams();

  useEffect(() => {
    const fetchPlanDetails = async () => {
      try {
        // Fetch plan details
        const planResponse = await networkService.get<any>(
          `${SERVICES_API}/${serviceId}/plans/${planId}`
        );
        const plan = planResponse.data;

        // Fetch licenses related to the plan
        const licenseResponse = await networkService.get<any>(
          `${PLAN_ON_LICENSE}/${planId}`
        );

        const licenses: License[] = licenseResponse.data;
        console.log(licenseResponse);

        // Calculate counts
        const uniqueOrganizations = new Set(
          licenses.map((license) => license.subscription.organization.id)
        );
        const customersCount = uniqueOrganizations.size;
        const probesCount = licenses.length;

        setPlanDetails({
          ...plan,
          customersCount,
          probesCount,
        });
        setLicenseData(licenses);
      } catch (error: any) {
        handleError({ error: error, message: error.message, level: "error" });
      }
    };

    if (accessToken) {
      fetchPlanDetails();
    }
  }, [accessToken, planId, serviceId]);

  if (!planDetails) {
    return (
      <div
        className={`flex flex-col flex-grow p-8 overflow-hidden ${collapsed ? "ml-20" : "ml-72"} transition-all duration-300`}>
        Loading...
      </div>
    );
  }

  return (
    <div
      className={`flex flex-col flex-grow p-8 overflow-hidden ${collapsed ? "ml-20" : "ml-72"} transition-all duration-300`}>
      <PageHeader title="Plans" />
      <div className="sm:px-0 mt-4">
        <h3 className="text-normal font-semibold leading-7 text-Neutral-900 font-space-grotesk">
          Plans &gt; Plan details
        </h3>
      </div>
      <div className="mt-4 border-t border-gray-100">
        <dl>
          <div className="py-2 flex items-start">
            <dt className="text-sm font-medium leading-6 text-Neutral-900 w-48 flex-shrink-0 font-space-grotesk">
              Name
            </dt>
            <dd className="mt-1 text-sm leading-6 text-Neutral-900 font-space-grotesk">
              {planDetails.name}
            </dd>
          </div>
          <div className="py-2 flex items-start w-full">
            <dt className="text-sm font-medium leading-6 text-Neutral-900 w-48 flex-shrink-0 font-space-grotesk">
              Description
            </dt>
            <dd className="mt-1 text-sm leading-6 text-Neutral-900 font-space-grotesk max-w-[600px] text-wrap break-words">
              {planDetails.description}
            </dd>
          </div>
          <div className="py-2 flex items-start">
            <dt className="text-sm font-medium leading-6 text-Neutral-900 w-48 flex-shrink-0 font-space-grotesk">
              Price
            </dt>
            <dd className="mt-1 text-sm leading-6 text-Neutral-900 flex items-center space-x-2 font-space-grotesk">
              <span>
                {planDetails.pricingModel.unitPrice}{" "}
                {planDetails.pricingModel.unit}
              </span>
            </dd>
          </div>
          <div className="py-2 flex items-start">
            <dt className="text-sm font-medium leading-6 text-Neutral-900 w-48 flex-shrink-0 font-space-grotesk">
              Trial Period
            </dt>
            <dd className="mt-1 text-sm leading-6 text-Neutral-900 flex items-center space-x-2 font-space-grotesk">
              <span>{planDetails.trialPeriod} Days</span>
            </dd>
          </div>
          <div className="py-2 flex items-start">
            <dt className="text-sm font-medium leading-6 text-Neutral-900 w-48 flex-shrink-0 font-space-grotesk">
              Billing Frequency
            </dt>
            <dd className="mt-1 text-sm leading-6 text-Neutral-900 flex items-center space-x-2 font-space-grotesk">
              <img
                src="/details/billing-frequency.svg"
                alt="Billing Frequency Icon"
                width={22}
                height={22}
              />
              <span>{planDetails.pricingModel.billingCycle}</span>
            </dd>
          </div>
          <div className="py-2 flex items-start">
            <dt className="text-sm font-medium leading-6 text-Neutral-900 w-48 flex-shrink-0 font-space-grotesk">
              Creation Date
            </dt>
            <dd className="mt-1 text-sm leading-6 text-Neutral-900 flex items-center space-x-2 font-space-grotesk">
              <img
                src="/details/creation-date.svg"
                alt="Creation Date Icon"
                width={22}
                height={22}
              />
              <span>{formatDate(planDetails.createdAt)}</span>
            </dd>
          </div>
          <div className="py-2 flex items-start">
            <dt className="text-sm font-medium leading-6 text-Neutral-900 w-48 flex-shrink-0 font-space-grotesk">
              Updated Date
            </dt>
            <dd className="mt-1 text-sm leading-6 text-Neutral-900 flex items-center space-x-2 font-space-grotesk">
              <img
                src="/details/updated-date.svg"
                alt="Updated Date Icon"
                width={22}
                height={22}
              />
              <span>{formatDate(planDetails.updatedAt)}</span>
            </dd>
          </div>
          <div className="py-2 flex items-start">
            <dt className="text-sm font-medium leading-6 text-Neutral-900 w-48 flex-shrink-0 font-space-grotesk">
              No. of Customers
            </dt>
            <dd className="mt-1 text-sm leading-6 text-Neutral-900 flex items-center space-x-2 font-space-grotesk">
              <img
                src="/details/customer-list.svg"
                alt="Customer List Icon"
                width={22}
                height={22}
              />
              <span>{planDetails.customersCount}</span>
            </dd>
          </div>
          <div className="py-2 flex items-start">
            <dt className="text-sm font-medium leading-6 text-Neutral-900 w-48 flex-shrink-0 font-space-grotesk">
              No. of Probes
            </dt>
            <dd className="mt-1 text-sm leading-6 text-Neutral-900 flex items-center space-x-2 font-space-grotesk">
              <img
                src="/details/no-of-probes.svg"
                alt="No of Probes Icon"
                width={22}
                height={22}
              />
              <span>{planDetails.probesCount}</span>
            </dd>
          </div>
          <div className="py-2 flex items-start">
            <dt className="text-sm font-medium leading-6 text-Neutral-900 w-48 flex-shrink-0 font-space-grotesk">
              Status
            </dt>
            <dd className="mt-1 text-sm leading-6 text-Neutral-900 flex items-center space-x-2 font-space-grotesk">
              {planDetails.isActive ? (
                <img
                  src="/details/price-icon.svg"
                  alt="Active Icon"
                  width={22}
                  height={22}
                />
              ) : (
                <img
                  src="/details/price-icon2.svg"
                  alt="Active Icon"
                  width={22}
                  height={22}
                />
              )}
              <span>{planDetails.isActive ? "Active" : "Inactive"}</span>
            </dd>
          </div>
          <div className="py-2 flex items-start">
            <dt className="text-sm font-medium leading-6 text-Neutral-900 w-48 flex-shrink-0 font-space-grotesk">
              Plan Type
            </dt>
            <dd className="text-sm leading-6 text-Neutral-900 font-space-grotesk">
              {planDetails.type === "paid" ? "PAID" : "FREE"}
            </dd>
          </div>
        </dl>
      </div>
      <div className="mt-12 flex flex-wrap">
        <Link
          to={`/home/update-plan/${planId}`}
          className="py-2 px-10 rounded-full border bg-pink-300 text-sm sm:text-base md:text-base font-space-grotesk">
          Update
        </Link>
      </div>
    </div>
  );
}
