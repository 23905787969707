import { useMemo, useState } from "react";
import { Item, SortConfig, TableColumn } from "~/interfaces/interfaces";
import { compareValues, getColumnComparator } from "./table.helper";

export const useTableSort = (
  items: Item<any>["attributes"][],
  columns: TableColumn[]
) => {
  const [sortConfig, setSortConfig] = useState<SortConfig | null>(null);

  const sortedItems = useMemo(() => {
    const sortableItems = [...items];
    if (sortConfig) {
      sortableItems.sort((a, b) => {
        return compareValues(
          a[sortConfig.key],
          b[sortConfig.key],
          sortConfig.direction,
          getColumnComparator(sortConfig.key, columns)
        );
      });
    }
    return sortableItems;
  }, [items, sortConfig, columns]);

  const handleSort = (key: keyof Item<any>["attributes"], type: string) => {
    let direction: "ascending" | "descending" = "ascending";
    if (
      sortConfig &&
      sortConfig.key === key &&
      sortConfig.direction === "ascending"
    ) {
      direction = "descending";
    }
    setSortConfig({ key, direction, type });
  };

  return { sortedItems, handleSort, sortConfig };
};
