import { useEffect, useState } from "react";
import ReactPaginate from "react-paginate";
import { useLocation } from "react-router-dom";

interface IPaginationComponent {
  onPageChange: (x) => void;
  totalRows: number;
  rowsPerPage: number;
  pageCount: number;
  resetToFirstPage?: boolean;
}

const PaginationComponent: React.FC<IPaginationComponent> = ({
  onPageChange,
  totalRows,
  rowsPerPage,
  pageCount,
  resetToFirstPage = true,
}) => {
  const location = useLocation();
  const [currentPage, setCurrentPage] = useState(1);
  const [dataKey, setDataKey] = useState(0);

  const handlePageClick = (selectedPage) => {
    setCurrentPage(selectedPage.selected + 1);
    onPageChange(selectedPage.selected + 1);
  };

  useEffect(() => {
    if (resetToFirstPage) {
      setCurrentPage(1);
      setDataKey((prevDataKey) => prevDataKey + 1);
    }
  }, [totalRows, location.pathname,resetToFirstPage]);

  const startIndex = (currentPage - 1) * rowsPerPage + 1;
  const endIndex = Math.min(currentPage * rowsPerPage, totalRows);

  return (
    <div className="flex w-full items-center justify-between rounded-b-2x px-1 py-1 pt-0 font-space-grotesk">
      <p className="text-sm text-gray-700">
        Showing <span className="font-medium">{startIndex}</span> to{" "}
        <span className="font-medium">{endIndex}</span> of{" "}
        <span className="font-medium">{totalRows}</span> results
      </p>
      <ReactPaginate
        key={dataKey}
        pageCount={pageCount}
        onPageChange={handlePageClick}
        previousLabel={
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            className="h-6 w-6"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M15.75 19.5L8.25 12l7.5-7.5"
            />
          </svg>
        }
        nextLabel={
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            className="h-6 w-6"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M8.25 4.5l7.5 7.5-7.5 7.5"
            />
          </svg>
        }
        breakLabel="..."
        breakClassName="py-2 px-3"
        marginPagesDisplayed={1}
        pageRangeDisplayed={4}
        containerClassName="flex items-center justify-center space-x-1 text-zinc-800"
        pageClassName=" hover:text-primary_orange cursor-pointer "
        pageLinkClassName="block py-2 px-3 rounded-md"
        previousClassName={`text-primary_orange cursor-pointer ${
          currentPage === 1 ? "disabled:opacity-60 cursor-not-allowed" : ""
        }`}
        nextClassName={`text-primary_orange cursor-pointer ${
          currentPage === pageCount
            ? "disabled:opacity-60 cursor-not-allowed"
            : ""
        }`}
        previousLinkClassName={`block py-2 px-3 rounded-md text-azo-blue ${
          currentPage === 1 ? "disabled:opacity-60 cursor-not-allowed" : ""
        }`}
        nextLinkClassName={`block py-2 px-3 rounded-md text-azo-blue ${
          currentPage === pageCount
            ? "disabled:opacity-60 cursor-not-allowed"
            : ""
        }`}
        activeClassName="border border-azo-blue rounded-md text-primary_orange bg-white-a700"
        disabledClassName="opacity-60"
      />
    </div>
  );
};

export default PaginationComponent;
