import { useEffect, useMemo, useState } from "react";
import { Item, SearchConfig } from "~/interfaces/interfaces";

export const useTableSearch = (
  searchField: string,
  searchTerm: string,
  items: Item<any>["attributes"][]
) => {
  const [searchConfig, setSearchConfig] = useState<SearchConfig | null>(null);

  useEffect(() => {
    if (searchField && searchTerm) {
      setSearchConfig({ key: searchField, searchTerm });
    }
  }, [searchField, searchTerm]);

  const filteredItems = useMemo(() => {
    if (searchConfig) {
      return items.filter((item) => {
        const searchKey = searchConfig.key.split(".");
        let nestedValue = item;
        for (const key of searchKey) {
          if (nestedValue[key] === undefined || nestedValue[key] === null) {
            return false;
          }
          nestedValue = nestedValue[key];
        }
        return nestedValue
          .toString()
          .toLowerCase()
          .includes(searchConfig.searchTerm.toLowerCase());
      });
    }
    return items;
  }, [items, searchConfig]);

  return filteredItems;
};
