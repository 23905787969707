import { ErrorMessage, Field, Form, Formik } from "formik";
import { useEffect, useState } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";
import * as Yup from "yup";
import networkService from "~/services/network.service";
import Carousel from "~/shared/components/carousel";
import CustomSelect from "~/shared/components/customSelect";
import PageHeader from "~/shared/components/page-header.component";
import { UserRole } from "~/shared/config";
import {
  DEVICE_LICENSE_DETAILS,
  GET_LICENSES_BY_ORG_ID,
  GET_MANUFACTURER,
  ORGANIZATION_PROBES_API,
  PROBE_DETAILS_API,
  REGISTER_DEVICE,
  UPDATE_DEVICE_ON_LICENSE,
} from "~/shared/constants/api";
import { handleError } from "~/shared/utils/errors.util";
import { validateNullishObjectFields } from "~/shared/utils/helper.util";
import useAuthStore from "~/store/auth.store";
import useSidebarStore from "~/store/sidebar.store";
interface ProbeData {
  device: {
    id: string;
    externalDeviceId: string;
    nickName: string;
    isActive: boolean;
    modelId: string;
    organization: {
      id: string;
      name: string;
      description: string;
      createdAt: string;
      updatedAt: string;
    };
    registrationStatus: string;
    createdAt: string;
    updatedAt: string;
    organizationId: string;
  };
  owner: {
    id: string;
    firstName: string;
    lastName: string;
    email: string;
  };
}

const UpdateProbeDetails = () => {
  const [initialValues, setInitialValues] = useState({
    manufacturer: {
      name: "",
      id: "",
      models: [{ modelName: "", id: "", instructions: "" }],
    },
    model: { modelName: "", id: "" },
    externalDeviceId: "",
    nickName: "",
    planName: "N/A",
    licenseId: "N/A",
  });
  const [manufacturers, setManufacturers] = useState([]);
  const [models, setModels] = useState([]);
  const navigate = useNavigate();
  const { orgId, deviceId } = useParams();
  const [searchParams] = useSearchParams();
  const [collapsed] = useSidebarStore((state) => [state.collapsed]);
  const licenseId = searchParams.get("licenseId");
  const [images, setImages] = useState<any[]>([]);
  const { user } = useAuthStore((state) => ({ user: state.user }));

  const fetchModelImages = async (modelId: string) => {
    try {
      const galleryResponse = await networkService.get<any>(
        `${GET_MANUFACTURER}/models/${modelId}/gallery`
      );

      const imagePromises = galleryResponse.data.gallery.map(
        async (img: any) => {
          const imageResponse = await networkService.get<Blob>(
            `${GET_MANUFACTURER}/models/${modelId}/gallery/${img.id}`,
            null,
            { responseType: "blob" }
          );
          if (!(imageResponse instanceof Blob)) {
            throw new Error("Response is not a Blob");
          }
          return URL.createObjectURL(imageResponse);
        }
      );
      setImages(await Promise.all(imagePromises));
    } catch (error: any) {
      handleError({ error: error, message: error.message, level: "error" });
      setImages([]);
    }
  };

  useEffect(() => {
    const fetchManufacturers = async () => {
      try {
        const response = await networkService.get<any>(GET_MANUFACTURER);
        setManufacturers(response.data);
        return response.data;
      } catch (error: any) {
        handleError({ error: error, message: error.message, level: "error" });
      }
    };
    const fetchDevice = async (manufacturers) => {
      try {
        const response = await networkService.get<any>(
          `${REGISTER_DEVICE}/device/${deviceId}`
        );
        const licenseResponse = await networkService.get<any>(
          `${DEVICE_LICENSE_DETAILS}/${deviceId}`
        );
        const data: ProbeData = response.data;
        const licenseData = licenseResponse.data[0];

        const manufacturer = manufacturers.find((man) =>
          man.models.some((model) => model.id === data.device.modelId)
        );
        const model = manufacturer
          ? manufacturer.models.find(
              (model) => model.id === data.device.modelId
            )
          : { modelName: "", id: "" };

        setInitialValues({
          manufacturer: manufacturer
            ? {
                name: manufacturer.name,
                id: manufacturer.id,
                models: manufacturer.models,
              }
            : {
                name: "",
                id: "",
                models: [{ name: "", id: "", instructions: "" }],
              },
          model: model
            ? { modelName: model.modelName, id: model.id }
            : { modelName: "", id: "" },
          externalDeviceId: data.device.externalDeviceId || "",
          nickName: data.device.nickName || "",
          licenseId: licenseData?.id ?? "N/A",
          planName: licenseData?.subscription?.plan?.name ?? "N/A",
        });
        if (manufacturer) {
          setModels(manufacturer.models);
        }
        fetchModelImages(model.id);
      } catch (error: any) {
        handleError({ error: error, message: error.message, level: "error" });
      }
    };
    const fetchLicense = async () => {
      try {
        if (licenseId) {
          const licenseResponse = await networkService.get<any>(
            `${GET_LICENSES_BY_ORG_ID}/${user?.organizationId}`
          );
          const licenseData = licenseResponse.data.results.filter(
            (f) => f.id === licenseId
          )[0];
          setInitialValues((prev) => ({
            ...prev,
            licenseId: licenseData?.id ?? "N/A",
            planName: licenseData?.subscription?.plan?.name ?? "N/A",
          }));
        }
      } catch (error: any) {
        handleError({ error: error, message: error.message, level: "error" });
      }
    };
    const getProbeDetails = async () => {
      try {
        const organizationProbesResponse = await networkService.get<any>(
          `${ORGANIZATION_PROBES_API}/${user?.organizationId}`
        );
        const probeCount = organizationProbesResponse.data.results.length;
        setInitialValues((prev) => ({
          ...prev,
          nickName: `probe-${probeCount > 0 ? probeCount : 1}`,
        }));
      } catch (error: any) {
        handleError({ error: error, message: error.message, level: "error" });
      }
    };
    user?.role === UserRole.Member && getProbeDetails();
    fetchManufacturers().then((data) => {
      if (deviceId !== "new") {
        fetchDevice(data);
      } else {
        fetchLicense();
      }
    });
  }, [deviceId, orgId, licenseId, user?.organizationId, user?.role]);

  const handleUpdate = async (values: {
    manufacturer: {
      name: string;
      id: string;
      models: {
        modelName: string;
        instructions: string;
        id: string;
      }[];
    };
    model: { id: string; modelName: string };
    externalDeviceId: string;
    nickName: string;
  }) => {
    console.log("values", values);
    if (
      !validateNullishObjectFields<{
        manufacturer: {
          name: string;
          id: string;
          models: {
            modelName: string;
            instructions: string;
            id: string;
          }[];
        };
        model: { id: string; modelName: string };
        externalDeviceId: string;
        nickName: string;
      }>(values)
    ) {
      toast.error("Please fill all the fields");
      return;
    }
    try {
      const updateManufacturerResponse = await networkService.put<any>(
        `${GET_MANUFACTURER}/models/${values.model.id}`,
        {
          manufacturerId: values.manufacturer.id,
        }
      );
      if (updateManufacturerResponse) {
        toast.success("Manufacturer updated successfully");
        const probeUpdateResponse = await networkService.put<any>(
          `${PROBE_DETAILS_API}/${orgId}/${deviceId}`,
          {
            modelId: values.model.id,
            externalDeviceId: values.externalDeviceId,
            nickName: values.nickName,
          }
        );
        if (probeUpdateResponse) {
          toast.success("Probe details updated successfully");
          navigate("/licenses");
        }
      }
    } catch (error: any) {
      handleError({ error: error, message: error.message, level: "error" });
    }
  };

  const handleCreateNewProbe = async (values: {
    model: { id: string; modelName: string };
    externalDeviceId: string;
    nickName: string;
  }) => {
    try {
      if (!licenseId) {
        toast.error("Please select a license to assign to this probe");
        return;
      }
      const response = await networkService.post<any>(
        `${REGISTER_DEVICE}/${orgId}/register`,
        {
          nickName: values.nickName,
          externalDeviceId: values.externalDeviceId,
          modelId: values.model.id,
          isActive: true,
        }
      );
      toast.success("Probe Created Successfully");
      if (response) {
        const deviceId = response.data.id;
        const addLicenseResponse = await networkService.put<any>(
          `${UPDATE_DEVICE_ON_LICENSE}/${licenseId}`,
          {
            deviceId: deviceId,
          }
        );
        if (addLicenseResponse) {
          toast.success("License assigned successfully");
          navigate("/licenses?downloadApp=true");
        }
      }
    } catch (error: any) {
      handleError({ error: error, message: error.message, level: "error" });
    }
  };

  const validationSchema = Yup.object({
    manufacturer: Yup.object({
      name: Yup.string(),
      id: Yup.string().required("Manufacturer is required"),
      models: Yup.array().of(
        Yup.object().shape({
          name: Yup.string(),
          id: Yup.string(),
          instructions: Yup.string(),
        })
      ),
    }).required("Manufacturer is required"),
    model: Yup.object({
      id: Yup.string().required("Model is required"),
      modelName: Yup.string(),
    }).required("Model is required"),
    externalDeviceId: Yup.string().required("Probe ID is required"),
    nickName: Yup.string().required("Probe Alias is required"),
  });
  return (
    <div
      className={`flex flex-col flex-grow p-8 overflow-hidden ${collapsed ? "ml-20" : "ml-72"} transition-all duration-300`}>
      <PageHeader title="Probes" />
      <div className="sm:px-0 mt-4">
        <h3 className="text-normal font-semibold leading-7 text-Neutral-900 font-space-grotesk">
          Probes &gt; {deviceId === "new" ? "Add New Probe" : "Update Probe"}
        </h3>
      </div>
      <div className="flex gap-1 h-full">
        <Formik
          className="w-1/2"
          enableReinitialize
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={(values, actions) => {
            if (deviceId === "new") {
              handleCreateNewProbe(values);
            } else {
              handleUpdate(values);
            }
            actions.setSubmitting(false);
          }}>
          {({ isSubmitting, setFieldValue, values }) => (
            <Form className="w-1/2">
              <div className="mt-4 space-y-4">
                {/* manufacturer info */}
                <div className="flex flex-col gap-3 max-w-[500px]">
                  <div>
                    <label
                      htmlFor="manufacturer.id"
                      className="block text-sm font-medium leading-6 text-gray-900">
                      Manufacturer
                    </label>
                    <CustomSelect
                      id="manufacturer.id"
                      name="manufacturer.id"
                      selectedValue={values.manufacturer.name}
                      onChangeHandler={(value: any) => {
                        setFieldValue("manufacturer", value);
                        setFieldValue("model", { modelName: "", id: "" });
                        setFieldValue("externalDeviceId", "");
                        setImages([]);
                        setModels(value.models);
                      }}>
                      {manufacturers.map((manufacturer: any) => (
                        <option key={manufacturer.id} value={manufacturer}>
                          {manufacturer.name}
                        </option>
                      ))}
                    </CustomSelect>
                    <ErrorMessage
                      name="manufacturer.id"
                      component="p"
                      className="text-red-500 text-xs mt-2"
                    />
                  </div>

                  <div>
                    <label
                      htmlFor="model.id"
                      className="block text-sm font-medium leading-6 text-gray-900">
                      Model
                    </label>
                    <CustomSelect
                      id="model.id"
                      name="model.id"
                      selectedValue={values.model.modelName}
                      onChangeHandler={(value: any) => {
                        setFieldValue("model", value);
                        fetchModelImages(value.id);
                      }}>
                      {models.map((model: any) => (
                        <option key={model.id} value={model}>
                          {model.modelName}
                        </option>
                      ))}
                    </CustomSelect>
                    <ErrorMessage
                      name="model.modelName"
                      component="p"
                      className="text-red-500 text-xs mt-2"
                    />
                  </div>
                </div>
                {/* probe info */}
                <div className="flex flex-col gap-3 max-w-[500px]">
                  <div>
                    <div className="flex items-center">
                      <label
                        htmlFor="externalDeviceId"
                        className="block text-sm font-medium leading-6 text-gray-900">
                        Probe Serial Number
                      </label>
                    </div>

                    <Field
                      id="externalDeviceId"
                      name="externalDeviceId"
                      type="text"
                      // value={initialValues.externalDeviceId}
                      placeholder="Enter Probe Id"
                      className="px-4 py-2 w-full rounded-[1rem] border-[1px] border-[#D0D0D6] placeholder-gray input-text focus:outline-none"
                    />
                    <ErrorMessage
                      name="externalDeviceId"
                      component="p"
                      className="text-red-500 text-xs mt-2"
                    />
                  </div>

                  <div>
                    <label
                      htmlFor="nickName"
                      className="block text-sm font-medium leading-6 text-gray-900">
                      Probe Name
                    </label>
                    <Field
                      id="nickName"
                      name="nickName"
                      type="text"
                      // value={initialValues.nickName}
                      placeholder="Enter Probe Alias"
                      className="px-4 py-2 w-full rounded-[1rem] border-[1px] border-[#D0D0D6] placeholder-gray input-text focus:outline-none"
                    />
                    <ErrorMessage
                      name="nickName"
                      component="p"
                      className="text-red-500 text-xs mt-2"
                    />
                  </div>
                </div>
              </div>
              {/* Buttons */}
              <div className=" mt-12 flex flex-wrap gap-4">
                <button
                  type="submit"
                  className="py-2 px-10 rounded-full border bg-pink-300 text-sm sm:text-base md:text-base font-space-grotesk"
                  disabled={isSubmitting}>
                  {`${deviceId === "new" ? "Add New" : "Update"}`}
                </button>
                <button
                  type="button"
                  onClick={() => navigate("/licenses")}
                  className="py-2 px-10 rounded-full border border-pink-300 text-sm sm:text-base md:text-base font-space-grotesk">
                  Cancel
                </button>
              </div>
            </Form>
          )}
        </Formik>
        <div className="w-1/2 mt-4 left-0">
          {images.length === 0 ? (
            <img
              src="/defaultImage.jpeg"
              alt="defaultImage"
              className="h-full object-contain max-h-[450px] ml-2 object-left-top"
            />
          ) : (
            <Carousel images={images} interval={6000} />
          )}
        </div>
      </div>
    </div>
  );
};

export default UpdateProbeDetails;
